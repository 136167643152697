import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getMenuWisePageConfigs = createAsyncThunk(
  "menuWisePageConfig/getMenuWisePageConfigs",
  async () => {
    const response = await dataFetch.get(
      "/api/menuwise-configuration-db/menuWisePageConfigs"
    );
    const data = await response.data.data;

    return data;
  }
);

export const getMenuWisePageConfig = createAsyncThunk(
  "menuWisePageConfig/getMenuWisePageConfig",
  async (params) => {
    const response = await dataFetch.get(
      `/api/menudb-app/menuWisePageConfig/${params}`
    );
    const data = await response.data.data;

    return data;
  }
);

const menuWisePageConfigSlice = createSlice({
  name: "menuWisePageConfig",
  initialState: {
    menuWisePageConfigs: [],
    menuWisePageConfig: null,
    currentSelectedMenuID: 1,
  },
  reducers: {
    setAllMenuWisePageConfigs: (state, { payload }) => {
      state.menuWisePageConfigs = payload;
    },
    setCurrentSelectedMenuID: (state, { payload }) => {
      localStorage.setItem("ClickedMenuID", payload);
      state.currentSelectedMenuID = payload;
    },
  },
  extraReducers: {
    [getMenuWisePageConfigs.pending]: () => {
      console.log("Fetching Menu Data...");
    },
    [getMenuWisePageConfigs.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getMenuWisePageConfigs.fulfilled]: (state, { payload }) => {
      return { ...state, menuWisePageConfigs: payload };
    },
    [getMenuWisePageConfig.pending]: () => {
      console.log("Fetching Menu Data...");
    },
    [getMenuWisePageConfig.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getMenuWisePageConfig.fulfilled]: (state, { payload }) => {
      return { ...state, menuWisePageConfig: payload };
    },
  },
});
export const { setAllMenuWisePageConfigs, setCurrentSelectedMenuID } =
  menuWisePageConfigSlice.actions;
export const getAllMenuWisePageConfigs = (state) =>
  state.menuWisePageConfig.menuWisePageConfigs;
export const getCurrentMenuWisePageConfig = (state) =>
  state.menuWisePageConfig.menuWisePageConfig;
export const getCurrentSelectedMenuID = (state) =>
  localStorage.getItem("ClickedMenuID")
    ? localStorage.getItem("ClickedMenuID")
    : state.menuWisePageConfig.currentSelectedMenuID;
export default menuWisePageConfigSlice.reducer;
