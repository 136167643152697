import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getTestimonial = createAsyncThunk(
  "testimonial/getTestimonial",
  async (params) => {
    const response = await dataFetch.get("/api/testimonial-db/testimonialData");
    const data = await response.data.data;
    return data;
  }
);
export const getTestimonialConfig = createAsyncThunk(
  "testimonial/getTestimonialConfig",
  async (params) => {
    const response = await dataFetch.get("/api/testimonial-db/testimonialConfig", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState: {
    testimonial: null,
    testimonialConfig: null,
  },
  reducers: {},
  extraReducers: {
    [getTestimonial.pending]: () => {
      console.log("Fetching testimonial Data...");
    },
    [getTestimonial.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getTestimonial.fulfilled]: (state, { payload }) => {
      return { ...state, testimonial: payload };
    },
    [getTestimonialConfig.pending]: () => {
      console.log("Fetching testimonial config Data...");
    },
    [getTestimonialConfig.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getTestimonialConfig.fulfilled]: (state, { payload }) => {
      return { ...state, testimonialConfig: payload };
    },
  },
});
export const {} = testimonialSlice.actions;
export const getTestimonialData = (state) => state.testimonial.testimonial;
export const getTestimonialConfigData = (state) =>
  state.testimonial.testimonialConfig;
export default testimonialSlice.reducer;
