import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getsServiceData = createAsyncThunk(
  "service/getsServiceData",
  async () => {
    const response = await dataFetch.get("/api/service-app/serviceData");
    const data = await response.data.data;
    return data;
  }
);
export const getsServiceDataByType = createAsyncThunk(
  "service/getsServiceDataByType",
  async (params) => {
    const response = await dataFetch.get(`/api/service-app/serviceDataByType/${params}`);
    const data = await response.data.data;
    return data;
  }
);
export const getServiceTypes = createAsyncThunk(
  "service/getServiceTypes",
  async (params) => {
    const response = await dataFetch.get("/api/service-app/serviceTypes");
    const data = await response.data.data;
    return data;
  }
);
export const getService = createAsyncThunk(
  "blog/getService",
  async (params) => {
    const response = await dataFetch.get(`/api/service-app/serviceById/${params}`);
    const data = await response.data.data;

    return data;
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    serviceData: [],
    serviceTypes: [],
    service: null,
  },
  reducers: {
    setAllServices: (state, { payload }) => {
      state.serviceData = payload;
    },
  },
  extraReducers: {
    [getsServiceData.pending]: () => {
      console.log("Fetching Service Data...");
    },
    [getsServiceData.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getsServiceData.fulfilled]: (state, { payload }) => {
      return { ...state, serviceData: payload };
    },
    [getsServiceDataByType.pending]: () => {
      console.log("Fetching Service Data By Type...");
    },
    [getsServiceDataByType.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getsServiceDataByType.fulfilled]: (state, { payload }) => {
      return { ...state, serviceData: payload };
    },
    [getServiceTypes.pending]: () => {
      console.log("Fetching Service Types...");
    },
    [getServiceTypes.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getServiceTypes.fulfilled]: (state, { payload }) => {
      return { ...state, serviceTypes: payload };
    },
    [getService.pending]: () => {
      console.log("Fetching Service...");
    },
    [getService.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getService.fulfilled]: (state, { payload }) => {
      return { ...state, service: payload };
    },
  },
});
export const { setAllServices } = serviceSlice.actions;
export const getAllServiceData = (state) => state.service.serviceData;
export const getAllServiceTypes = (state) => state.service.serviceTypes;
export const getCurrentService = (state) => state.service.service;
export default serviceSlice.reducer;
