import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gridGenerator } from "../helper/helper";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import {
  getTestimonial,
  getTestimonialConfig,
  getTestimonialConfigData,
  getTestimonialData,
} from "../redux/testimonial/testimonialSlice";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getScreenSize } from "../redux/theme/themeSlice";
import { delay } from "lodash";

const Testimonial = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const testimonialData = useSelector(getTestimonialData);
  const testimonialConfigs = useSelector(getTestimonialConfigData);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const screenSize = useSelector(getScreenSize);

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getTestimonial());
    dispatch(getTestimonialConfig());
  }, []);

  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(
        testimonialConfigs &&
          testimonialConfigs.configs &&
          testimonialConfigs.configs.column_count
      )
    );
  }, [testimonialConfigs]);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <div className="w-full mx-auto  min-h-screen overflow-hidden pb-24 p-4">
      {testimonialData && (
        <>
          <div className="relative mb-12">
            <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
              {currentPageConfig && currentPageConfig.menu_title}
            </h2>
            <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
              {currentPageConfig && currentPageConfig.shadow_title}
            </h2>
          </div>
          {testimonialConfigs &&
          testimonialConfigs.configs &&
          testimonialConfigs.configs.testimonial_header_title ? (
            <label className="flex justify-center text-4xl underline font-bold text-slate-500">
              {testimonialConfigs.configs.testimonial_header_title}
            </label>
          ) : null}
          <div className="mt-[100px]">
            <Swiper
              navigation={true}
              pagination={pagination}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper"
              spaceBetween={10}
              loop={true}
              autoplay={{
                delay:
                  testimonialConfigs &&
                  testimonialConfigs.configs &&
                  testimonialConfigs.configs.is_autoplay
                    ? testimonialConfigs &&
                      testimonialConfigs.configs &&
                      testimonialConfigs.configs.autoplay_delay
                    : 50000000,
              }}
              slidesPerView={
                screenSize < 600
                  ? 1
                  : screenSize < 900
                  ? 2
                  : testimonialConfigs &&
                    testimonialConfigs.configs &&
                    testimonialConfigs.configs.column_count
              }
            >
              {testimonialData &&
                testimonialData.length > 0 &&
                testimonialData.map((item, index) => (
                  <SwiperSlide>
                    <div
                      key={index}
                      className="h-[450px] overflow-y-auto myCarousel shadow flex flex-col bg-gradient-to-tr from-indigo-100 to-purple-100 px-2 lg:px-4 2xl:!px-16 !pt-28 mx-auto w-full justify-start"
                    >
                      <div className="border-l-4 border-l-zinc-400 pl-8 pr-4 border-r-2 border-r-zinc-300">
                        <blockquote className="relative text-justify pl-6 text-sm">
                          {item.comment}
                        </blockquote>
                        {testimonialConfigs &&
                        testimonialConfigs.configs &&
                        testimonialConfigs.configs.show_image ? (
                          <img
                            src={item.commenter_img}
                            className="!h-[150px] !w-[150px] !rounded-[50%] !border-8 !border-white absolute top-[0%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
                          />
                        ) : null}
                      </div>
                      <label className="pt-8">
                        {testimonialConfigs &&
                        testimonialConfigs.configs &&
                        testimonialConfigs.configs.show_commenter_name ? (
                          <span className="font-semibold text-xl text-indigo-600 pr-4">
                            {item.commenter_name}
                          </span>
                        ) : null}
                        {testimonialConfigs &&
                        testimonialConfigs.configs &&
                        testimonialConfigs.configs.show_commenter_designation ? (
                          <span className="font-semibold text-sm text-slate-500">
                            /{item.commenter_designation}
                          </span>
                        ) : null}
                        {testimonialConfigs &&
                        testimonialConfigs.configs &&
                        testimonialConfigs.configs.show_comment_date ? (
                          <span className="font-normal text-xs text-slate-400">
                            {" "}
                            - {item.comment_date}
                          </span>
                        ) : null}
                      </label>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
};

export default Testimonial;
