import { createSlice } from "@reduxjs/toolkit";
const themeSlice = createSlice({
  name: "theme",
  initialState: {
    mode: "light",
    theme: "purple",
    screenSize: 0,
    isCollapsedSideMenu: true,
  },
  reducers: {
    setMode: (state, action) => {
      return { ...state, mode: action.payload };
    },
    setTheme: (state, action) => {
      return { ...state, theme: action.payload };
    },
    setScreenSize: (state, action) => {
      return { ...state, screenSize: action.payload };
    },
    setSideMenuStatus: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
});
export const { setMode, setTheme, setScreenSize, setSideMenuStatus } =
  themeSlice.actions;
export const getMode = (state) => state.theme.mode;
export const getTheme = (state) => state.theme.theme;
export const getScreenSize = (state) => state.theme.screenSize;
export const getSideMenuStatus = (state) => state.theme.isCollapsedSideMenu;
export default themeSlice.reducer;
