import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GalleryDetails from "../components/gallery/GalleryDetails";
import GalleryItem from "../components/gallery/GalleryItem";
import Loader from "../components/loader/Loader";
import { gridGenerator } from "../helper/helper";
import {
  getGalleryConfig,
  getGalleryConfigData,
  getGalleryData,
  getGalleryList,
} from "../redux/gallery/gallerySlice";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";

const Gallery = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const [isLoading, setIsLoading] = useState(true);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const galleryData = useSelector(getGalleryData);
  const galleryConfig = useSelector(getGalleryConfigData);

  useEffect(() => {
    dispatch(getGalleryList());
    dispatch(getGalleryConfig());
    dispatch(getMenuWisePageConfig(currentMenuID)).then(() =>
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    );
  }, [currentMenuID]);
  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);

  return (
    <div className="w-full mx-auto rounded-3xl  min-h-screen p-4">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
          {currentPageConfig && currentPageConfig.menu_title}
        </h2>
        <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
          {currentPageConfig && currentPageConfig.shadow_title}
        </h2>
      </div>

      <div
        className={`${gridGeneratorClassName} w-full xl:w-[80%] xl:mx-auto relative mt-16`}
      >
        {galleryData &&
          galleryData.length > 0 &&
          galleryData.map((item, index) => (
            <GalleryItem
              key={item.id}
              item={item}
              galleryConfig={galleryConfig}
            />
          ))}
      </div>
    </div>
  );
};

export default Gallery;
