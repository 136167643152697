import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServiceItem from "../components/service/ServiceItem";
import { gridGenerator } from "../helper/helper";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import {
  getAllServiceData,
  getAllServiceTypes,
  getServiceTypes,
  getsServiceDataByType,
} from "../redux/service/serviceSlice";

const Service = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const [isLoading, setIsLoading] = useState(true);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const serviceData = useSelector(getAllServiceData);
  const serviceTypes = useSelector(getAllServiceTypes);

  const [selectedItem, setSelectedItem] = useState("All");
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getsServiceDataByType("All"));
    dispatch(getServiceTypes());
  }, [currentMenuID]);
  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);
  const handleSelectServiceType = (e, type) => {
    setSelectedItem(type);
    dispatch(getsServiceDataByType(type));
  };
  return (
    <div>
      <div className="w-full mx-auto rounded-3xl min-h-screen p-4">
        <div className="relative">
          <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
            {currentPageConfig && currentPageConfig.menu_title}
          </h2>
          <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
            {currentPageConfig && currentPageConfig.shadow_title}
          </h2>
        </div>
        <div className="w-full xl:w-[80%] xl:mx-auto my-8">
          <ul className="w-full flex items-center justify-center gap-4 flex-wrap">
            <li
              className={`${
                selectedItem == "All" ? "active " : ""
              } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
              data-filter="*"
              onClick={(e) => handleSelectServiceType(e, "All")}
            >
              All
            </li>
            {serviceTypes &&
              serviceTypes.length > 0 &&
              serviceTypes.map((item, index) => (
                <li
                  key={item.id}
                  className={`${
                    selectedItem == item ? "active " : ""
                  } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
                  data-filter=".corporate"
                  onClick={(e) => handleSelectServiceType(e, item)}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>

        <div className="w-full xl:w-[80%] xl:mx-auto  mx-auto">
          <div className={`${gridGeneratorClassName} gap-4`}>
            {serviceData &&
              serviceData.length &&
              serviceData.map((item, index) => (
                <ServiceItem
                  key={item.id}
                  item={item}
                  currentPageConfig={currentPageConfig}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
