import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import {
  getCurrentGalleryData,
  getGallery,
} from "../../redux/gallery/gallerySlice";
import { gridGenerator } from "../../helper/helper";
import urlConfig from "../../helper/urlConfig";
const GalleryDetails = ({ album_id, handleCloseDrawer, galleryConfig }) => {
  const dispatch = useDispatch();
  const selectedGallery = useSelector(getCurrentGalleryData);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const [isClickedImage, setIsClickedImage] = useState(false);
  const [clickedImage, setClickedImage] = useState(null);

  useEffect(() => {
    dispatch(getGallery(album_id));
  }, [album_id]);
  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(galleryConfig && galleryConfig.configs.column_count)
    );
  }, [galleryConfig]);
  const handleClose = (e) => {
    e.preventDefault();
    handleCloseDrawer();
  };
  const handleClickImage = (e, url) => {
    setIsClickedImage(true);
    setClickedImage(url);
  };
  const handleCloseImage = (e) => {
    setIsClickedImage(false);
    setClickedImage(null);
  };
  const [customTag, setCustomTag] = useState([])
  useEffect(() => {
    if(selectedGallery && selectedGallery.tags && selectedGallery.tags.length > 0) {
      let list = [];
      selectedGallery.tags.map(x=> {
        list.push(x.name);
      })
      setCustomTag(list)
    }
  }, [selectedGallery])
  return (
    <div>
      {selectedGallery ? (
        <div className="p-4">
          <div className="flex items-center justify-end">
            <span
              className="border hover:cursor-pointer text-[20px] py-1 px-3 "
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
          <div className="mt-3">
            <div>
              <div>
                <label className="font-semibold text-sm ">Album Title: </label>
                <span className="text-slate-600">
                  {selectedGallery.album_title}
                </span>
              </div>
              <div>
                <label className="font-semibold text-sm ">
                  Album Description:{" "}
                </label>
                <span className="text-slate-600">
                  {selectedGallery.album_description}
                </span>
              </div>
              <div>
                <label className="font-semibold text-sm ">Location: </label>
                <span className="text-slate-600">
                  {selectedGallery.location}
                </span>
              </div>
              <div>
                <label className="font-semibold text-sm ">Year: </label>
                <span className="text-slate-600">{selectedGallery.year}</span>
              </div>
              <div>
                <label className="font-semibold text-sm ">Tags: </label>
                <span className="text-slate-600">
                      {customTag.length > 0 &&
                        customTag.join(",")}</span>
              </div>
            </div>
            <div className={`${gridGeneratorClassName} w-full mt-4`}>
              {selectedGallery &&
                selectedGallery.media &&
                selectedGallery.media.length > 0 &&
                selectedGallery.media.map((item, index) => (
                  <div key={index} className="w-full">
                    <img
                    crossOrigin="anonymous" 
                    src={urlConfig.baseUrl+item.img}
                      alt={item.type}
                      className="h-[120px] w-full rounded-lg hover:cursor-pointer"
                      onClick={(e) => handleClickImage(e, urlConfig.baseUrl+item.img)}
                    />
                    {/* <img crossOrigin="anonymous" src={urlConfig.baseUrl+selectedBlog.img} alt={selectedBlog.title} className="w-full h-[220px] md:h-[380px] rounded-xl mb-6" /> */}
            
                  </div>
                ))}
            </div>
            {isClickedImage && (
              <div className="fixed left-0 right-0 top-0 bottom-0 bg-slate-800/50">
                <span
                  onClick={(e) => handleCloseImage(e)}
                  className="hover:cursor-pointer bg-white px-3 py-1 absolute left-[10px] top-[50px]"
                >
                  &times;
                </span>
                <img
                crossOrigin="anonymous"
                  className="h-[80%] w-[80%] mx-auto my-[5%]"
                  src={clickedImage}
                />
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default GalleryDetails;
