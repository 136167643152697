import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import BooksAndPublicationsItem from "../components/booksAndPublications/BooksAndPublicationsItem";

import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentMenuWisePageConfig,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import { getCurrentSelectedMenuID } from "../redux/menu/menuWisePageConfigSlice";

import { gridGenerator } from "../helper/helper";
import { getRowsPerPage } from "../redux/menu/menuSlice";
import Loader from "../components/loader/Loader";
import {
  getAllblogs,
  getBlogByTags,
  getBlogs,
  getPostByType,
} from "../redux/blog/blogSlice";
import SideSearch from "../components/search/SideSearch";
import SideSearchBooksAndPublications from "../components/search/SideSearchBooksAndPublications";
import researchGateImg from "./../assets/images/researchgate.png";
import googlescholarImg from "./../assets/images/googlescholar.png";
import academiaImg from "./../assets/images/academia.png";


const BooksAndPublications = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const blogData = useSelector(getAllblogs);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = useSelector(getRowsPerPage);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [initialTag, setInititalTag] = useState(["All"]);
  const [selectedItem, setSelectedItem] = useState("publications");
  useEffect(() => {
    let obj = {
      type: "booksAndPublications",
      tag: initialTag,
    };
    // dispatch(getBlogByTags(obj));
    dispatch(getPostByType(selectedItem));
    dispatch(getMenuWisePageConfig(currentMenuID)).then(() =>
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    );
  }, [currentMenuID]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      blogData && blogData.length > 0 && blogData.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(blogData && blogData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, blogData]);

  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % blogData.length;
    setItemOffset(newOffset);
  };

  const handleChangeTags = (tags) => {
    let obj = {
      type: selectedItem,
      tag: tags,
    };
    dispatch(getBlogByTags(obj));
  };

  const handleSelectType = (e, type) => {
    setSelectedItem(type);
    dispatch(getPostByType(type));
  };
  return (
    <div className=" p-4">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
          {currentPageConfig && currentPageConfig.menu_title}
        </h2>
        <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
          {currentPageConfig && currentPageConfig.shadow_title}
        </h2>
      </div>
      <div className="w-full xl:w-[80%] xl:mx-auto my-8">
        
            <a
              className="flex items-center mx-2 gap-4 float-right my-6 bg-indigo-500 hover:bg-indigo-700 text-white px-8 py-2 whitespace-nowrap rounded-full"
              href="https://scholar.google.com/citations?user=pQZkFOoAAAAJ&hl=en"
              target="_blank"
              // onClick={(e) => handleRedirect(e, "/contact")}
            >
            <img src={googlescholarImg} className="w-[30px] h-[30px] rounded-full"/>
              <label>Google Scholar</label>
            </a>
            <a
              className="flex items-center mx-2 gap-4 float-right my-6 bg-indigo-500 hover:bg-indigo-700 text-white px-8 py-2 whitespace-nowrap rounded-full"
              href="https://aust.academia.edu/AAlMuyeed"
              target="_blank"
              // onClick={(e) => handleRedirect(e, "/contact")}
            >
            <img src={academiaImg} className="w-[30px] h-[30px] rounded-full"/>
              <label>Academia</label>
            </a>
            <a
              className="flex items-center mx-2 gap-4 float-right my-6 bg-indigo-500 hover:bg-indigo-700 text-white px-8 py-2 whitespace-nowrap rounded-full"
              href="https://www.researchgate.net/profile/Abdullah_Al-Muyeed"
              target="_blank"
              // onClick={(e) => handleRedirect(e, "/contact")}
            >
            <img src={researchGateImg} className="w-[30px] h-[30px]"/>
              <label>ResearchGate</label>
            </a>
        <ul className="w-full flex items-center justify-center gap-4 flex-wrap relative">
          {/* <li
            className={`${
              selectedItem == "All" ? "active " : ""
            } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
            data-filter="*"
            onClick={(e) => handleSelectType(e, "All")}
          >
            All
          </li> */}
          {["publications", "books"].map((item, index) => (
            <li
              key={item.id}
              className={`${
                selectedItem == item ? "active " : ""
              } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
              data-filter=".corporate"
              onClick={(e) => handleSelectType(e, item)}
            >
              {item}
            </li>
          ))}
          
        </ul>
      </div>
      <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4 mt-8">
        <div
          className={`${
            currentPageConfig && currentPageConfig.sidebarSearch
              ? "flex-[3]"
              : "w-full flex-1"
          }  flex flex-col`}
        >
          {currentItems && currentItems.length > 0 ? (
            <div className={`${gridGeneratorClassName}`}>
              {currentItems.map((item, index) => (
                <BooksAndPublicationsItem
                  key={item.id}
                  item={item}
                  currentPageConfig={currentPageConfig}
                  selectedItem={selectedItem}
                />
              ))}
            </div>
          ) : (
            <label className="text-3xl font-semibold text-slate-600 flex items-center justify-center">
              No Data Found
            </label>
          )}

          <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            className="w-full flex items-center justify-center gap-6"
            activeClassName="bg-slate-200 rounded-[50%] py-2"
            pageLinkClassName="border px-4 py-2 rounded-[50%] hover:bg-slate-200"
          />
        </div>
        <div
          className={`${
            currentPageConfig && currentPageConfig.sidebarSearch
              ? "flex-1"
              : "hidden"
          }  flex flex-col`}
        >
          <SideSearchBooksAndPublications
            handleChangeTags={handleChangeTags}
            postType={selectedItem}
          />
        </div>
      </div>
    </div>
  );
};

export default BooksAndPublications;
