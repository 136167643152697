import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./@fake-db";
import "./App.css";
import Content from "./components/content/Content";
import Sidebar from "./components/sidebar/Sidebar";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BooksAndPublications from "./pages/BooksAndPublications";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import NewsAndEvents from "./pages/NewsAndEvents";
import PolicyAdvocacy from "./pages/PolicyAdvocacy";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
import Testimonial from "./pages/Testimonial";
import Timeline from "./pages/Timeline";
import { getAllMenu } from "./redux/menu/menuSlice";
import { setCurrentSelectedMenuID } from "./redux/menu/menuWisePageConfigSlice";
import { getIsCollapsed } from "./redux/sidebar/sidebarSlice";
import {
  getScreenSize,
  getSideMenuStatus,
  setScreenSize,
  setSideMenuStatus,
} from "./redux/theme/themeSlice";
import Navbar from "./components/navbar/Navbar";

function App() {
  //   const isCollapsed = useSelector(getIsCollapsed);
  const menuList = useSelector(getAllMenu);
  const dispatch = useDispatch();
  const screenSize = useSelector(getScreenSize);
  const isCollapsed = useSelector(getSideMenuStatus);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenSize(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (localStorage.getItem("CurrentMenuID")) {
      localStorage.removeItem("CurrentMenuID");
    }
  }, []);
  useEffect(() => {
    if (screenSize <= 900) {
      dispatch(setSideMenuStatus(true));
    } else {
      dispatch(setSideMenuStatus(false));
    }
  }, [screenSize]);
  return (
    <div className="portfolio__app">
      <Navbar />
      <div>
        <div className={`${screenSize < 1024 ? "mt-[74px]" : ""}`}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/testimonial" element={<Testimonial />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/timeline" element={<Timeline />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/news-events" element={<NewsAndEvents />} />
            <Route
              exact
              path="/books-publications"
              element={<BooksAndPublications />}
            />
            <Route exact path="/policy-advocacy" element={<PolicyAdvocacy />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
