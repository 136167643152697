import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getsPortfolioData = createAsyncThunk(
  "portfolio/getsPortfolioData",
  async () => {
    const response = await dataFetch.get("/api/portfolio-app/portfolioData");
    const data = await response.data.data;
    return data;
  }
);
export const getsPortfolioDataByType = createAsyncThunk(
  "portfolio/getsPortfolioDataByType",
  async (params) => {
    const response = await dataFetch.get(`/api/portfolio-app/portfolioDataByType/${params}`);
    const data = await response.data.data;
    return data;
  }
);
export const getPortfolioTypes = createAsyncThunk(
  "portfolio/getPortfolioTypes",
  async (params) => {
    const response = await dataFetch.get("/api/portfolio-app/portfolioTypes", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);
export const getPortfolio = createAsyncThunk(
  "blog/getPortfolio",
  async (params) => {
    const response = await dataFetch.get(`/api/portfolio-app/getPortfolioById/${params}`);
    const data = await response.data.data;

    return data;
  }
);

const portfolioSlice = createSlice({
  name: "portfolio",
  initialState: {
    portfolioData: [],
    portfolioTypes: [],
    portfolio: null,
  },
  reducers: {
    setAllPortfolios: (state, { payload }) => {
      state.portfolioData = payload;
    },
  },
  extraReducers: {
    [getsPortfolioData.pending]: () => {
      console.log("Fetching Portfolio Data...");
    },
    [getsPortfolioData.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getsPortfolioData.fulfilled]: (state, { payload }) => {
      return { ...state, portfolioData: payload };
    },
    [getsPortfolioDataByType.pending]: () => {
      console.log("Fetching Portfolio Data By Type...");
    },
    [getsPortfolioDataByType.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getsPortfolioDataByType.fulfilled]: (state, { payload }) => {
      return { ...state, portfolioData: payload };
    },
    [getPortfolioTypes.pending]: () => {
      console.log("Fetching Portfolio Types...");
    },
    [getPortfolioTypes.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getPortfolioTypes.fulfilled]: (state, { payload }) => {
      return { ...state, portfolioTypes: payload };
    },
    [getPortfolio.pending]: () => {
      console.log("Fetching Portfolio...");
    },
    [getPortfolio.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getPortfolio.fulfilled]: (state, { payload }) => {
      return { ...state, portfolio: payload };
    },
  },
});
export const { setAllPortfolios } = portfolioSlice.actions;
export const getAllPortfolioData = (state) => state.portfolio.portfolioData;
export const getAllPortfolioTypes = (state) => state.portfolio.portfolioTypes;
export const getCurrentPortfolio = (state) => state.portfolio.portfolio;
export default portfolioSlice.reducer;
