import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import urlConfig from "../../helper/urlConfig";
import {
  getCurrentPortfolio,
  getPortfolio,
} from "../../redux/portfolio/portfolioSlice";

const PortfolioDetails = ({ id, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const selectedPortfolio = useSelector(getCurrentPortfolio);

  useEffect(() => {
    dispatch(getPortfolio(id));
  }, [id]);
  const handleClose = (e) => {
    e.preventDefault();
    handleCloseDrawer();
  };
  return (
    <div>
      {selectedPortfolio ? (
        <div className="p-4 ">
          <div className="flex items-center justify-end">
            <span
              className="border hover:cursor-pointer text-[20px] py-1 px-3 "
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
          <div className="mt-3">
            <img
              crossOrigin="anonymous"
              src={urlConfig.baseUrl + selectedPortfolio.img}
              alt={selectedPortfolio.title}
              className="h-full w-full rounded-2xl"
            />
          </div>
          <hr className="my-6" />
          <p className="font-semibold text-slate-600 text-xl my-4">
            {selectedPortfolio.title}
          </p>
          <p
            className="font-semibold text-slate-600 text-xl"
            dangerouslySetInnerHTML={{
              __html: selectedPortfolio.short_description,
            }}
          ></p>
          <p
            className="text-slate-500 text-sm mt-4"
            dangerouslySetInnerHTML={{ __html: selectedPortfolio.description }}
          ></p>
        </div>
      ) : null}
    </div>
  );
};

export default PortfolioDetails;
