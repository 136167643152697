export const gridGenerator = (column) => {
  switch (column) {
    case 1:
      return "grid grid-cols-1 gap-4";

    case 2:
      return "grid grid-cols-1 gap-4 md:grid-cols-2";

    case 3:
      return "grid grid-cols-1 gap-4 md:grid-cols-3";

    case 4:
      return "grid grid-cols-1 gap-4 md:grid-cols-4";

    case 5:
      return "grid grid-cols-1 gap-4 md:grid-cols-5";

    case 6:
      return "grid grid-cols-1 gap-4 md:grid-cols-6";

    default:
      return "grid grid-cols-1 gap-4 md:grid-cols-1";
  }
};

export const captionLabelClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "font-semibold  text-normal flex text-slate-600 items-end pb-2";

    case 2:
      return "font-semibold  text-normal flex text-slate-600 items-end pb-2";

    case 3:
      return "font-semibold text-sm flex text-slate-600 items-end pb-2";

    case 4:
      return "font-semibold  text-sm flex text-slate-600 items-end pb-2";

    case 5:
      return "font-semibold  text-xs flex text-slate-600 items-end pb-1";

    case 6:
      return "font-semibold  text-xs flex text-slate-600 items-end pb-1";

    default:
      return "font-semibold  text-sm flex text-slate-600 items-end";
  }
};

export const imageClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "h-[270px] lg:h-[370px]";

    case 2:
      return "h-[240px] lg:h-[240px]";

    case 3:
      return "h-[250px] lg:h-[250px]";

    case 4:
      return "h-[180px] lg:h-[180px]";

    case 5:
      return "h-[120px] lg:h-[120px]";

    case 6:
      return "h-[100px] lg:h-[100px]";

    default:
      return "h-[200px] lg:h-[320px]";
  }
};

export const headingClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "text-xl font-bold py-6";

    case 2:
      return "text-xl font-semibold py-6";

    case 3:
      return "text-normal font-bold py-4]";

    case 4:
      return "text-sm font-bold py-3";

    case 5:
      return "text-xs font-bold py-3";

    case 6:
      return "text-xs font-bold py-3";

    default:
      return "text-xl font-bold py-6";
  }
};

export const paddingClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "px-4";

    case 2:
      return "px-4";

    case 3:
      return "px-3";

    case 4:
      return "px-2";

    case 5:
      return "px-1";

    case 6:
      return "px-1";

    default:
      return "px-8";
  }
};

export const sectionClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "text-slate-600 text-normal py-4";

    case 2:
      return "text-slate-600 text-normal py-3";

    case 3:
      return "text-slate-600 text-sm py-2";

    case 4:
      return "text-slate-600 text-sm py-2";

    case 5:
      return "text-slate-600 text-xs py-1";

    case 6:
      return "text-slate-600 text-xs py-1";

    default:
      return "text-slate-600 text-normal py-4";
  }
};

export const buttonClassGenerator = (column) => {
  switch (column) {
    case 1:
      return "text-black rounded-md text-md flex items-center gap-3 px-2 py-2";

    case 2:
      return "text-black rounded-md text-md flex items-center gap-3 px-2 py-2";

    case 3:
      return "text-black rounded-md text-md flex items-center gap-2 px-2 py-2";

    case 4:
      return "text-black rounded-md text-md flex items-center gap-2 px-2 py-2";

    case 5:
      return "text-black rounded-md text-md flex items-center gap-1 px-1 py-1";

    case 6:
      return "text-black rounded-md text-md flex items-center gap-1 px-1 py-1";

    default:
      return "text-black rounded-md text-normal flex items-center gap-3 px-2 py-2";
  }
};

export const renderContent = (column, content) => {
  switch (column) {
    case 1:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    case 2:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    case 3:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    case 4:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    case 5:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    case 6:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;

    default:
      return <p dangerouslySetInnerHTML={{__html: content}} ></p>;
  }
};
