import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClientList from "../components/home/ClientList";
import RecentActivity from "../components/home/RecentActivity";
import { gridGenerator } from "../helper/helper";
import profilePic from "../assets/images/Profile_2.jpg";
import urlConfig from "../helper/urlConfig";
import {
  getAbout,
  getAboutCounterConfig,
  getCurrentabout,
  getCurrentaboutConfig,
} from "../redux/about/aboutSlice";
import {
  getClientData,
  getClientList,
  getHomeConfig,
  getHomeConfigData,
  getHomeSliderData,
  getHomeSliderList,
  getRecentActivityData,
  getRecentActivityList,
} from "../redux/home/homeSlice";
import { getMenu, getMenuByPath } from "../redux/menu/menuSlice";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
  setCurrentSelectedMenuID,
} from "../redux/menu/menuWisePageConfigSlice";
import { getScreenSize } from "../redux/theme/themeSlice";
import Carousel from "better-react-carousel";
import academiaImg from "../assets/images/academia.jpg";
import academiaImg1 from "../assets/images/academia.png";
import professionalImg from "../assets/images/professional.png";
import researchGateImg from "../assets/images/researchgate.png";
import googlescholarImg from "../assets/images/googlescholar.png";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const sliderData = useSelector(getHomeSliderData);
  const configData = useSelector(getHomeConfigData);
  const [activeSlide, setActiveSlide] = useState(0);
  const aboutConfigs = useSelector(getCurrentaboutConfig);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const screenSize = useSelector(getScreenSize);
  const clientData = useSelector(getClientData);

  const aboutData = useSelector(getCurrentabout);

  let slideInterval;
  let slideIntervalTime = 7000;
  let autoScroll = true;
  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getHomeSliderList());
    dispatch(getHomeConfig());
    dispatch(getClientList());

    dispatch(getAbout());
    dispatch(getAboutCounterConfig());
  }, []);

  const [slides, setSlider] = useState([]);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSliders = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}api/sliders`,
        {
          signal,
        }
      );
      // console.log(res)

      if (res.status === 204) {
        setSlider([]);
      } else {
        setSlider(res.data.data);
      }
    };
    getSliders();
    // console.log(latestNews)
    return () => {
      abortController.abort();
    };
  }, []);

  const nextSlide = () => {
    setActiveSlide(activeSlide == sliderData.length - 1 ? 0 : activeSlide + 1);
  };
  function auto() {
    slideInterval = setInterval(nextSlide, slideIntervalTime);
  }

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [activeSlide]);

  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(
        aboutConfigs &&
          aboutConfigs.configs &&
          aboutConfigs.configs.column_count
      )
    );
  }, [aboutConfigs]);

  const handleRedirect = (e, path, type) => {
    e.preventDefault();
    dispatch(getMenuByPath(path)).then((res) => {
      dispatch(setCurrentSelectedMenuID(res && res.payload.id));
    });
    navigate("/" + path, { replace: true, state: { type: type } });
  };

  return (
    <div className="w-full mx-auto min-h-screen mb-6">
      <>
        <div className={`w-full`}>
          <div className="home-container">
            <div className="md:h-[600px] 2xl:h-[700px] relative">
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="w-full md:h-[600px] 2xl:h-[700px]"
                      crossOrigin="anonymous"
                      loading="lazy"
                      src={
                        process.env.REACT_APP_PUBLIC_URL +
                        slide.img.split("/webapi.aamuyeed.com").pop()
                      }
                    />
                    <div
                      className="w-full absolute my-12 md:my-0 md:w-[50%] md:bg-black/60 md:left-0 
                    md:top-0 md:h-full flex items-start flex-col justify-center px-4 md:px-12"
                    >
                      <p className="flex items-start flex-col w-full text-left">
                        <span className="font-normal text-sm md:text-xl lg:text-2xl text-center md:text-left text-white relative py-2 md:py-3 text-lg md:text-1xl lg:text-2xl">
                          {slide.sub_title}
                        </span>
                        <span className="font-normal py-3 md:font-bold text-2xl text-white md:text-white lg:text-4xl text-center md:text-left">
                          {slide.title}
                        </span>
                      </p>
                      {/* <p className={`flex items-start flex-col mt-4 w-full`}>
                        <label className="font-normal md:font-semibold text-white md:text-white text-xl">
                          {slide.slider_text}
                        </label>
                      </p> */}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        <div className="p-4">
          <div className="flex flex-col lg:flex-row w-4/5 mx-auto">
            <div className="w-full lg:w-1/2 p-4 lg:text-justify">
              <p className="font-bold text-4xl text-black py-3">
                WHAT IS IT ABOUT?
              </p>
              <p>
                Dr. Abdullah Al-Muyeed, a seasoned development professional,
                boasts over 20 years of specialized experience in diverse
                environmental disciplines. Currently, he serves as the Chief
                Operating Officer (COO) of the CWIS-FSM Support Cell within the
                Department of Public Health Engineering (DPHE), operating under
                the Local Government Division of the Government of Bangladesh.
                Dr. Muyeed's role is pivotal in the institutionalization of the
                CWIS-FSM Support Cell, which, backed by the Bill & Melinda Gates
                Foundation, serves as a cornerstone in the nation's sanitation
                and waste management efforts, aligning with the Sustainable
                Development Goal (SDG) 6.2. In his capacity as COO, Dr. Muyeed
                collaborates with the Planning Commission to drive nationwide
                achievements of SDG6.2 and cultivates strategic partnerships
                with development entities. His responsibilities encompass
                ensuring transparent and accountable financial management and
                overseeing the government-led project life cycle, specifically
                in the realm of sanitation and waste management, following the
                citywide inclusive sanitation (CWIS) approach.<br></br><br></br> Previously, as
                the Head of Policy and Advocacy at WaterAid, Dr. Muyeed played a
                significant role in developing and managing large-scale WASH
                programs, particularly in challenging contexts of Bangladesh.
                His innovative approach to co-composting faecal sludge and solid
                waste has become a scalable model, recognized not only in
                Bangladesh but also in South Asia and various African countries.
                His pioneering work is acknowledged by the inclusion of the
                Sakhipur case study in Bangladesh's 8th Five Year plan as a
                scalable citywide model. Presently, Dr. Muyeed is involved in
                NextGen sanitation technologies, including contextualized and
                affordable pyrolysis, gasification, plasma, and the
                implementation of the OmniProcessor in Bangladesh—a
                state-of-the-art technology for sanitation and waste management
                developed by the Bill and Melinda Gates Foundation. Dr. Muyeed's
                influence is evident in his major contributions to
                national-level policies and strategies related to WASH. His
                leadership qualities shine through in donor relationship
                management, networking, and team leadership. Recognized for his
                innovation, he received the Innovation Ambassador Award in 2016
                and the Hackathon Award in 2020 for developing a COVID-19
                Tracker app. As an accomplished author, his publications,
                including the award-winning "Water and Environmental
                Engineering," reflect his commitment to advancing sustainable
                development and environmental engineering, establishing him as a
                prominent figure in the field.
              </p>
            </div>
            {/* <div className="w-full lg:w-1/2 p-4 flex items-center justify-center">
              <img
                src={profilePic}
                alt="Image Description"
                className="w-full h-auto ml-auto lg:w-1/2 lg:h-auto lg:mx-0"
              />
            </div> */}
            <div className="w-full lg:w-1/2 p-4 flex items-center justify-center">
  <img
    src={profilePic}
    alt="Image Description"
    className="w-full h-auto lg:w-2/3 mx-auto"
  />
</div>

          </div>
        </div>

        {/* career */}
        <div className="flex items-center flex-col lg:flex-row">
          <div className="flex-1 px-24 py-16 academia flex flex-col justify-center items-center gap-6">
            <p className="text-normal text-2xl font-semibold z-[10] text-white text-center">
              Without hardwork and discipline it is difficult to be a top
              professional
            </p>
            <button
              className="rounded-full px-5 py-2 bg-indigo-500 hover:bg-indigo-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "portfolio", "professional")}
            >
              Professional Attribute
            </button>
            <div className="w-full flex items-center gap-3 flex-row z-[10] flex-wrap">
              {clientData &&
                clientData.clientListData &&
                clientData.clientListData.length > 0 &&
                clientData.clientListData.map((item, index) => (
                  <div key={index}>
                    <img
                      crossOrigin="anonymous"
                      src={urlConfig.baseUrl + item.img}
                      className="w-[50px] h-[50px] rounded-2xl"
                      alt={Math.random()}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="flex-1 px-24 py-16 professional flex flex-col justify-center items-center gap-6">
            <p className="text-normal text-2xl font-semibold z-[10] text-white text-center">
              Excellence is the gradual result of always striving to do better
            </p>
            <button
              className="rounded-full px-5 py-2 bg-yellow-500 hover:bg-yellow-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "portfolio", "academia")}
            >
              Academic Excellence
            </button>
            <div className="w-full flex items-center justify-center gap-3 flex-row z-[10] flex-wrap">
              <img
                crossOrigin="anonymous"
                src={researchGateImg}
                className="w-[50px] h-[50px] rounded-2xl"
                alt={Math.random()}
              />
              <img
                crossOrigin="anonymous"
                src={academiaImg1}
                className="w-[50px] h-[50px] rounded-2xl"
                alt={Math.random()}
              />
              <img
                crossOrigin="anonymous"
                src={googlescholarImg}
                className="w-[50px] h-[50px] rounded-2xl"
                alt={Math.random()}
              />
            </div>
          </div>
        </div>

        {/* blog */}
        <div className="flex items-center flex-col lg:flex-row">
          <div className="flex-1 px-24 py-16 blog flex flex-col justify-center items-center gap-6">
            <p className="text-3xl font-semibold z-[10] text-white text-center">
              The best form of expression is BLOGING
            </p>
            <button
              className="rounded-full px-5 py-2 bg-cyan-500 hover:bg-cyan-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "blog")}
            >
              My Blogs
            </button>
          </div>
        </div>

        {/* book and news */}
        <div className="flex items-center flex-col lg:flex-row">
          <div className="flex-1 px-24 py-16 books flex flex-col justify-center items-center gap-6">
            <p className="text-3xl font-semibold z-[10] text-white text-center">
              Research is formalized curiosity. It is poking and prying with
              purpose
            </p>
            <button
              className="rounded-full px-5 py-2 bg-teal-500 hover:bg-teal-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "books-publications")}
            >
              Books & Publications
            </button>
          </div>
          <div className="flex-1 px-24 py-16 news flex flex-col justify-center items-center gap-6">
            <p className="text-3xl font-semibold z-[10] text-white text-center">
              News is what somebody, somewhere wants to suppress
            </p>
            <button
              className="rounded-full px-5 py-2 bg-pink-600 hover:bg-pink-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "news-events")}
            >
              News & Events
            </button>
          </div>
        </div>

        {/* contact */}
        <div className="flex items-center flex-col lg:flex-row">
          <div className="flex-1 px-24 py-16 contact flex flex-col justify-center items-center gap-6">
            <h2 className="text-white font-bold text-4xl z-[10] text-center">
              Get in Touch
            </h2>
            <p className="text-normal font-semibold z-[10] text-white text-center">
              I would love to hear from you
            </p>
            <button
              className="rounded-full px-5 py-2 bg-indigo-500 hover:bg-indigo-700 z-[10] max-w-fit"
              onClick={(e) => handleRedirect(e, "contact")}
            >
              Contact Me
            </button>
            <div className="w-full  flex  gap-3 md:flex-row flex-wrap px-2 md:px-4 justify-center z-[10]">
              <a
                href="https://www.facebook.com/aamuyeed"
                target="_blank"
                className="uil uil-facebook text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
              ></a>
              <a
                href="https://www.youtube.com/channel/UC2yAPnC4e4NQrn2H9f_2kjA"
                target="_blank"
                className="uil uil-youtube text-white bg-indigo-500 hover:bg-indigo-700 rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
              ></a>
              <a
                href="https://www.linkedin.com/in/amuyeed/"
                target="_blank"
                className="uil uil-linkedin text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
              ></a>
              <a
                href="https://twitter.com/aamuyeed"
                target="_blank"
                className="uil uil-twitter text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
              ></a>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default Home;
