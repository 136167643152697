import React, { useEffect, useState } from "react";
import { UilUser } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { UilComments } from "@iconscout/react-unicons";
import { UilAngleDoubleRight } from "@iconscout/react-unicons";

import { gridGenerator } from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import GalleryDetails from "./GalleryDetails";
import urlConfig from "../../helper/urlConfig";

const GalleryItem = ({ item, galleryConfig }) => {
  const dispatch = useDispatch();
  const {
    album_id,
    album_title,
    album_description,
    location,
    year,
    tags,
    primary_img,
  } = item;
  const [isItemClicked, setIsItemClicked] = useState(false);
  const [primary, setPrimary] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  // useEffect(() => {
  //   if (media && media.length > 0) {
  //     let obj =
  //       media.filter((x) => x.is_primary == true).length > 0
  //         ? media.filter((x) => x.is_primary == true)[0]
  //         : null;
  //     setPrimary(obj.img);
  //   }
  // }, [media]);

  const handleViewAlbum = (e, id) => {
    e.preventDefault();
    setIsItemClicked(true);
  };
  const handleCloseDrawer = (e) => {
    setIsItemClicked(false);
  };
  function onOverlayClick(e) {
    handleCloseDrawer();
    e.stopPropagation();
  }
  function onModalClick(e) {
    // need to stop propagation to ModalOverlay onClick event
    e.stopPropagation();
  }

  // Function to handle mouse enter
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [customTag, setCustomTag] = useState([]);
  useEffect(() => {
    if (tags && tags.length > 0) {
      let list = [];
      tags.split(",").map((x) => {
        list.push(x);
      });
      setCustomTag(list);
    }
  }, [tags]);
  return (
    <div
  className={`w-full flex items-start gap-1 mb-6  rounded-md p-4 h-[500px] relative box-shadow-style`}
  onMouseEnter={() => handleMouseEnter()}
  onMouseLeave={() => handleMouseLeave()}
>
  <div className="w-full flex flex-col gap-3">
    {primary_img ? (
      <div className={`relative image-container ${isHovered ? "hover" : ""}`}>
        <img
          crossOrigin="anonymous"
          src={urlConfig.baseUrl + primary_img}
          alt=""
          className={`h-[160px] w-full ${isHovered ? "blur" : ""}`}
        />
        <button
          type="button"
          className={`view-album-button text-white px-5 py-2 flex items-center justify-between absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity ${isHovered ? "opacity-100" : ""}`}
          onClick={(e) => handleViewAlbum(e, album_id)}
        >
           Read More
            <span>
              <UilAngleDoubleRight />
            </span>
        </button>
      </div>
    ) : (
      <label className="font-bold text-slate-500">No Image Found</label>
    )}
    <h2 className="font-semibold text-slate-600">Title: {album_title}</h2>
    <p className="text-xs">{album_description.slice(0, 150)}...</p>
    <h3 className="font-semibold text-sm text-slate-600">
      Location: {location}
    </h3>
    <h3 className="font-semibold text-sm text-slate-600">Year: {year}</h3>
  </div>
  {isItemClicked ? (
    <div
      onClick={onOverlayClick}
      className="cursor-default z-[1000] fixed top-0 bottom-0 left-0 right-0 bg-slate-800/50 dark:bg-gray-800"
      tabIndex="-1"
    >
      <div
        onClick={onModalClick}
        className="p-8 fixed right-0 bottom-0 top-0 overflow-y-auto opacity-1 w-[90%] md:w-[60%] xl:w-[40%] bg-white z-[2000]"
      >
        <GalleryDetails
          album_id={album_id}
          handleCloseDrawer={handleCloseDrawer}
          galleryConfig={galleryConfig}
        />
      </div>
    </div>
  ) : null}
</div>

  );
};

export default GalleryItem;
