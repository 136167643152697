import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getHomeSliderList = createAsyncThunk(
  "home/getHomeSliderList",
  async (params) => {
    const config = {
      headers : {
        'Access-Control-Allow-Origin': '*',
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }
    }
    const response = await dataFetch.get("/api/home-db/sliderData", config, {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);
export const getRecentActivityList = createAsyncThunk(
  "home/getRecentActivityList",
  async (params) => {
    const response = await dataFetch.get("/api/home-db/recentActivityData", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);
export const getClientList = createAsyncThunk(
  "home/getClientList",
  async (params) => {
    const response = await dataFetch.get("/api/home-db/clientData", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);
export const getHomeConfig = createAsyncThunk(
  "home/getHomeConfig",
  async () => {
    const response = await dataFetch.get("/api/home-db/homeConfigData");
    const data = await response.data.data;
    return data;
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState: {
    homeSliderData: [],
    recentActivityData: [],
    clientData: null,
    homeConfig: null,
  },
  reducers: {},
  extraReducers: {
    [getHomeSliderList.pending]: () => {
      console.log("Fetching home slider List...");
    },
    [getHomeSliderList.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getHomeSliderList.fulfilled]: (state, { payload }) => {
      return { ...state, homeSliderData: payload };
    },
    [getRecentActivityList.pending]: () => {
      console.log("Fetching activity List...");
    },
    [getRecentActivityList.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getRecentActivityList.fulfilled]: (state, { payload }) => {
      return { ...state, recentActivityData: payload };
    },
    [getClientList.pending]: () => {
      console.log("Fetching Client List...");
    },
    [getClientList.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getClientList.fulfilled]: (state, { payload }) => {
      return { ...state, clientData: payload };
    },
    [getHomeConfig.pending]: () => {
      console.log("Fetching home config Data...");
    },
    [getHomeConfig.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getHomeConfig.fulfilled]: (state, { payload }) => {
      return { ...state, homeConfig: payload };
    },
  },
});
export const {} = homeSlice.actions;
export const getHomeSliderData = (state) => state.home.homeSliderData;
export const getRecentActivityData = (state) => state.home.recentActivityData;
export const getClientData = (state) => state.home.clientData;
export const getHomeConfigData = (state) => state.home.homeConfig;
export default homeSlice.reducer;
