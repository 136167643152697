import React, { useEffect, useState } from "react";
import logo from "./../../assets/images/muyeed_logo_2.png";

import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllMenu, getMenus } from "../../redux/menu/menuSlice";
import {
  setCurrentSelectedMenuID,
  getCurrentSelectedMenuID,
} from "../../redux/menu/menuWisePageConfigSlice";
import {
  getScreenSize,
  getSideMenuStatus,
  setSideMenuStatus,
} from "../../redux/theme/themeSlice";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const dispatch = useDispatch();

  const isCollapsed = useSelector(getSideMenuStatus);
  const menuList = useSelector(getAllMenu);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const clickedMenu = useSelector(getCurrentSelectedMenuID);
  const screenSize = useSelector(getScreenSize);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (screenSize < 1024) setIsOpen(true);
    else setIsOpen(false);
  }, [screenSize]);

  useEffect(() => {
    dispatch(getMenus());
  }, []);

  const handleSidebarCollapse = (e) => {
    e.preventDefault();
    dispatch(setSideMenuStatus(!isCollapsed));
  };
  const handleMenuClick = (e, id) => {
    dispatch(setCurrentSelectedMenuID(id));
    dispatch(setCurrentSelectedMenuID(id));
  };

  return (
    <>
      {!isOpen ? (
        <nav className="bg-blue-500 py-4 sticky top-0 z-50">
          <div className="container mx-auto flex justify-center items-center">
            {/* Logo */}
            <Link to="/home" className="text-white text-2xl font-semibold mr-4">
            <img src={logo} alt="logo" style={{height: '50px'}}></img>
            </Link>
            {/* <a href="/home" className="text-white text-2xl font-semibold mr-4">
              <img src={logo} alt="logo" style={{height: '50px'}}></img>
            </a> */}

            {/* Navigation Links */}
            <ul className="flex ml-auto space-x-4">
              {menuList &&
                menuList.length > 0 &&
                menuList.map((item, index) => (
                  <Link
                    key={item.id}
                    to={`${item.menu_path + ""}`}
                    className=" "
                  >
                    <li
                      className={`${
                        item.id == clickedMenu
                          ? "active border-l-4 border-l-indigo-600"
                          : ""
                      }
                    ${item.id == clickedMenu ? "text-white" : "text-white"} 
                    flex items-center  gap-3 p-2 py-3 rounded-md hover:bg-blue-800	 hover:cursor-pointer`}
                      onClick={(e) => handleMenuClick(e, item.id)}
                    >
                      {<span>{item.menu_name}</span>}
                    </li>
                  </Link>
                ))}
            </ul>
          </div>
        </nav>
      ) : (
        <MobileMenu />
      )}
    </>
  );
};

export default Navbar;
