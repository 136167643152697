import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getAbout = createAsyncThunk("about/getAbout", async (params) => {
  const response = await dataFetch.get("/api/about-db/about", { params });
  const data = await response.data.data;
  return data;
});
export const getAboutCounterConfig = createAsyncThunk(
  "about/getAboutCounterConfig",
  async (params) => {
    const response = await dataFetch.get("/api/about-db/configAndCounters", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);

const aboutSlice = createSlice({
  name: "about",
  initialState: {
    about: null,
    aboutCounterConfig: null,
  },
  reducers: {},
  extraReducers: {
    [getAbout.pending]: () => {
      console.log("Fetching about Data...");
    },
    [getAbout.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getAbout.fulfilled]: (state, { payload }) => {
      return { ...state, about: payload };
    },
    [getAboutCounterConfig.pending]: () => {
      console.log("Fetching about config Data...");
    },
    [getAboutCounterConfig.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getAboutCounterConfig.fulfilled]: (state, { payload }) => {
      return { ...state, aboutCounterConfig: payload };
    },
  },
});
export const {} = aboutSlice.actions;
export const getCurrentabout = (state) => state.about.about;
export const getCurrentaboutConfig = (state) => state.about.aboutCounterConfig;
export default aboutSlice.reducer;
