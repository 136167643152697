import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { imageClassGenerator } from "../../helper/helper";
import urlConfig from "../../helper/urlConfig";
import PortfolioDetails from "./PortfolioDetails";

const PortfolioItem = (props) => {
  const { id, title, type, liveLink, img, short_description } = props.item;
  const dispatch = useDispatch();
  const [imageClass, setImageClass] = useState("");
  const [isItemClicked, setIsItemClicked] = useState(false);
  useEffect(() => {
    setImageClass(
      imageClassGenerator(
        props.currentPageConfig && props.currentPageConfig.columns
      )
    );
  }, [props.currentPageConfig]);

  const handleCloseDrawer = (e) => {
    setIsItemClicked(false);
  };
  function onOverlayClick(e) {
    handleCloseDrawer();
    e.stopPropagation();
  }
  function onModalClick(e) {
    // need to stop propagation to ModalOverlay onClick event
    e.stopPropagation();
  }
  return (
    <div className=" w-[500px] rounded-xl relative  hover:cursor-pointer " id="portfolio_item">
      <div className={`${imageClass}`}>
        <img
          crossOrigin="anonymous"
          src={urlConfig.baseUrl + img}
          alt={title}
          className="h-full w-full rounded-2xl"
        />
      </div>
      <div className="p-inner">
        <h5 className="mt-2 text-slate-700 font-semibold">{title}</h5>
        {/* <p className="text-sm text-slkate-600">{short_description}</p> */}
      </div>

      <div class="portfolio_item_hover text-center w-full flex items-center justify-center gap-4 sm:flex-col lg:flex-row px-6">
        <button
          className="px-3 py-1 rounded-full border border-blue-700 hover:bg-indigo-700 hover:text-white flex items-center justify-center gap-2 sm:w-full"
          onClick={(e) => setIsItemClicked(true)}
        >
          <i class="uil uil-expand-arrows"></i> Details
        </button>
        {/* <button className="px-3 py-1 rounded-full border border-blue-700 hover:bg-indigo-700 hover:text-white flex items-center justify-center gap-2 sm:w-full">
          <i className="uil uil-eye"></i> URL
        </button> */}
      </div>
      {isItemClicked ? (
        <div
          onClick={onOverlayClick}
          className="cursor-default z-[1000] fixed top-0 bottom-0 left-0 right-0 bg-slate-800/50 dark:bg-gray-800"
          tabIndex="-1"
        >
          <div
            onClick={onModalClick}
            className="p-8 fixed right-0 bottom-0 top-0  overflow-y-auto opacity-1 w-[90%] md:w-[60%] xl:w-[40%] bg-white z-[2000]"
          >
            <PortfolioDetails id={id} handleCloseDrawer={handleCloseDrawer} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PortfolioItem;
