import React, { useEffect, useState } from "react";
import { UilUser } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { UilComments } from "@iconscout/react-unicons";
import { UilAngleDoubleRight } from "@iconscout/react-unicons";

import {
  captionLabelClassGenerator,
  imageClassGenerator,
  headingClassGenerator,
  paddingClassGenerator,
  sectionClassGenerator,
  buttonClassGenerator,
  renderContent,
} from "../../helper/helper";
import { useDispatch, useSelector } from "react-redux";
import { getBlog, getCurrentblog } from "../../redux/blog/blogSlice";
import NewsAndEventsDetails from "./NewsAndEventsDetails";
import urlConfig from "../../helper/urlConfig";

const NewsAndEventsItem = ({ item, currentPageConfig, type }) => {
  const dispatch = useDispatch();
  const {
    id,
    images,
    tags,
    title,
    author,
    publish_date,
    views,
    comments,
    content,
    url,
  } = item;

  const [labelClass, setLabelClass] = useState("");
  const [imageClass, setImageClass] = useState("");
  const [headingClass, setHeadingClass] = useState("");
  const [paddingClass, setPaddingClass] = useState("");
  const [sectionClass, setSectionClass] = useState("");
  const [buttonClass, setButtonClass] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [isItemClicked, setIsItemClicked] = useState(false);
  const [clickedItem, setClickedItem] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setLabelClass(
      captionLabelClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setImageClass(
      imageClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setHeadingClass(
      headingClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setPaddingClass(
      paddingClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setSectionClass(
      sectionClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setButtonClass(
      buttonClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setGeneratedContent(
      renderContent(currentPageConfig && currentPageConfig.columns, content)
    );
  }, [currentPageConfig]);
  const handleReadMore = (e, id) => {
    e.preventDefault();
    setIsItemClicked(true);
  };
  const handleCloseDrawer = (e) => {
    setIsItemClicked(false);
  };
  function onOverlayClick(e) {
    handleCloseDrawer();
    e.stopPropagation();
  }
  function onModalClick(e) {
    // need to stop propagation to ModalOverlay onClick event
    e.stopPropagation();
  }
  const [customTag, setCustomTag] = useState([]);

    // Function to handle mouse enter
    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    // Function to handle mouse leave
    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  useEffect(() => {
    if (tags && tags.length > 0) {
      let list = [];
      tags.map((x) => {
        list.push(x.name);
      });
      setCustomTag(list);
    }
  }, [tags]);

  const handleReadMoreLatest = (e, url) => {
    e.preventDefault();
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <div
  className={`w-full flex items-start gap-1 rounded-md box-shadow-style mb-6 h-[500px] relative`}
>
  <div
    className={`${
      currentPageConfig &&
      currentPageConfig.post_summary_info_type == "left"
        ? "w-[30%]"
        : "w-0 hidden"
    } flex flex-col items-end gap-4 break-all`}
  ></div>
  <div
    className={`${
      currentPageConfig &&
      currentPageConfig.post_summary_info_type == "left"
        ? "w-[70%]"
        : "w-full"
    } ${paddingClass}`}
  >
    <div
      className={`${imageClass} relative`}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <img
        crossOrigin="anonymous"
        src={
          images && images.length > 0
            ? urlConfig.baseUrl + images[0].upload_path
            : null
        }
        alt={title}
        className={`h-full w-full rounded-md ${
          isHovered ? "blur" : ""
        }`}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <button
          type="button"
          className={`${
            buttonClass
          } opacity-0 transition-opacity ${
            isHovered ? "opacity-100" : ""
          }`}
          onClick={(e) => handleReadMoreLatest(e, url)}
        >
        Read More
          <span>
            <UilAngleDoubleRight />
          </span>
        </button>
      </div>
    </div>
    {currentPageConfig &&
    currentPageConfig.post_summary_info_type == "below-image" ? (
      <div></div>
    ) : null}
    <div className="">
      <h2 className={`${headingClass}`}>{title.slice(0, 100)}</h2>
      <section className={`${sectionClass}`}>
        <p dangerouslySetInnerHTML={{ __html: content.substring(0, 200) }}></p>
      </section>
    </div>
  </div>
</div>


  );
};

export default NewsAndEventsItem;
