import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gridGenerator } from "../helper/helper";
import {
  getAbout,
  getAboutCounterConfig,
  getCurrentabout,
  getCurrentaboutConfig,
} from "../redux/about/aboutSlice";
import { getMenuByPath } from "../redux/menu/menuSlice";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
  setCurrentSelectedMenuID,
} from "../redux/menu/menuWisePageConfigSlice";
import { useNavigate } from "react-router-dom";
import urlConfig from "../helper/urlConfig";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const About = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const aboutData = useSelector(getCurrentabout);
  const aboutConfigs = useSelector(getCurrentaboutConfig);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getAbout());
    dispatch(getAboutCounterConfig());
  }, [currentMenuID]);
  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(
        aboutConfigs &&
          aboutConfigs.configs &&
          aboutConfigs.configs.column_count
      )
    );
  }, [aboutConfigs]);
  const handleRedirect = (e, path) => {
    e.preventDefault();
    dispatch(getMenuByPath(path.substring(1, path.length))).then((res) => {
      dispatch(setCurrentSelectedMenuID(res && res.payload.id));
    });
    navigate(path);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    // {currentPageConfig ? }
    <div className="w-full mx-auto rounded-3xl min-h-screen max-h-screen overflow-y-auto p-4">
      {aboutData && (
        <>
          <div className="relative">
            <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
              {currentPageConfig && currentPageConfig.menu_title}
            </h2>
            <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
              {currentPageConfig && currentPageConfig.shadow_title}
            </h2>
          </div>

          <div className=" xl:w-[80%] xl:mx-auto my-12">
            <div className="flex gap-4 flex-col items-center justify-center">
              <div className=" flex flex-col gap-6">
                <p className="flex items-start flex-col">
                  <span className="font-medium text-xl text-slate-500 relative">
                    My friends call me
                    {/* <div className="absolute h-[2px] w-[50px] right-[-50px] top-[20px] bg-indigo-700"></div> */}
                  </span>
                  <span className="font-medium text-5xl text-slate-600">
                    {/* {aboutData && aboutData.profile_header_value} */}Muyeed
                  </span>
                </p>
              </div>
              <div className="w-full flex flex-col gap-5 items-center justify-center">
                <img
                  crossOrigin="anonymous"
                  className="rounded-[50%] w-[150px] h-[150px] sm:h-[200px] sm:w-[200px] md:h-[260px] md:w-[260px] lg:h-[350px] lg:w-[350px] xl:h-[350px] xl:w-[350px]"
                  src={urlConfig.baseUrl + aboutData[0].img}
                  alt={aboutData[0] && aboutData[0].name}
                />
                <div className="w-full flex flex-1 items-stretch md:items-center flex-col md:flex-row justify-center gap-5">
                  <button
                    className="bg-white border border-indigo-700 hover:bg-indigo-700 hover:text-white text-black px-8 py-2  "
                    onClick={(e) => handleRedirect(e, "/timeline")}
                  >
                    My Timeline
                  </button>
                  <button
                    className="bg-indigo-500 hover:bg-indigo-700 text-white px-8 py-2 whitespace-nowrap "
                    onClick={(e) => handleRedirect(e, "/contact")}
                  >
                    Contact Me
                  </button>
                </div>
              </div>
            </div>

            <div className="flex items-start my-8">
              <p
                className="w-full text-slate-600"
                dangerouslySetInnerHTML={{
                  __html: aboutData[0] && aboutData[0].description,
                }}
              ></p>
            </div>
          </div>

          <div
            className={`${gridGeneratorClassName} w-full xl:w-[80%] xl:mx-auto `}
          >
            {aboutConfigs &&
            aboutConfigs.configs &&
            aboutConfigs.configs.is_visible
              ? aboutConfigs &&
                aboutConfigs.counters &&
                aboutConfigs.counters.length > 0 &&
                aboutConfigs.counters.map((item, index) => (
                  <div
                    key={index}
                    className={`w-full flex ${
                      aboutConfigs &&
                      aboutConfigs.configs &&
                      aboutConfigs.configs.title_position == "top"
                        ? "flex-col-reverse"
                        : "flex-col"
                    }  items-center justify-center bg-slate-200 py-12 rounded-xl shadow-lg px-2`}
                  >
                    <label className="break-all text-5xl sm:text-3xl lg:text-5xl font-bold text-slate-700">
                      {item.count}
                    </label>
                    <label className="font-semibold text-slate-600 text-xl">
                      {item.title}
                    </label>
                  </div>
                ))
              : null}
          </div>

          <div className=" xl:w-[80%] xl:mx-auto my-12">
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                // height: 224,
              }}
            >
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  borderColor: "divider",
                }}
              >
                <Tab label="Academic Qualifications" {...a11yProps(0)} />
                <Tab label="Professional Attachments" {...a11yProps(1)} />
                <Tab label="Trainings" {...a11yProps(2)} />
                <Tab label="Awards" {...a11yProps(3)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutData[0].additional_field_1,
                  }}
                ></div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutData[0].additional_field_2,
                  }}
                ></div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutData[0].additional_field_3,
                  }}
                ></div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: aboutData[0].additional_field_4,
                  }}
                ></div>
              </TabPanel>
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default About;
