import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    isCollapsed: false,
  },
  reducers: {
    setIsCollapsed: (state, { payload }) => {
      state.isCollapsed = payload;
    },
  },
  extraReducers: {},
});
export const { setIsCollapsed } = sidebarSlice.actions;
export const getIsCollapsed = (state) => state.sidebar.isCollapsed;
export default sidebarSlice.reducer;
