import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PortfolioItem from "../components/portfolio/PortfolioItem";
import { gridGenerator } from "../helper/helper";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import {
  getAllPortfolioData,
  getAllPortfolioTypes,
  getPortfolioTypes,
  getsPortfolioDataByType,
} from "../redux/portfolio/portfolioSlice";

const Portfolio = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const [isLoading, setIsLoading] = useState(true);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const portfolioData = useSelector(getAllPortfolioData);
  const portfolioTypes = useSelector(getAllPortfolioTypes);

  const [selectedItem, setSelectedItem] = useState("All");
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getPortfolioTypes());
    if(location && location.state && location.state.type != undefined && location.state.type != null && location.state.type != "" ) {
      setSelectedItem(location.state.type);
      
    dispatch(getsPortfolioDataByType(location.state.type));
    } else {
      
    dispatch(getsPortfolioDataByType("All"));
    }
  }, [currentMenuID]);
  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);
  const handleSelectPortfolioType = (e, type) => {
    setSelectedItem(type);
    dispatch(getsPortfolioDataByType(type));
  };
  return (
    <div>
      <div className="w-full mx-auto rounded-3xl min-h-screen p-4">
        <div className="relative">
          <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
            {currentPageConfig && currentPageConfig.menu_title}
          </h2>
          <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
            {currentPageConfig && currentPageConfig.shadow_title}
          </h2>
        </div>

        <div className="w-full xl:w-[80%] xl:mx-auto  mx-auto">
          {/* <div className={`${gridGeneratorClassName} gap-4 portfolio`}> */}
          <div className={`w-full flex items-center flex-col portfolio`}>
            {portfolioData &&
              portfolioData.length &&
              portfolioData.map((item, index) => (
                <PortfolioItem
                  key={item.id}
                  item={item}
                  currentPageConfig={currentPageConfig}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
