import urlConfig from "./urlConfig";

export class Util {
  constructor(module) {
    this.baseUrl = urlConfig && urlConfig.baseUrl;
  }

  getBaseUrl = () => {
    return this.baseUrl;
  };
}
