// import "./db/menu-db";
// import "./db/menuwise-configuration-db";
// // import "./db/timeline-db";
// // import "./db/post-db";
// import "./db/portfolio-db";
// import "./db/about-db";
// import "./db/testimonial-db";
// import "./db/home-db";
// import "./db/service-db";
// import "./db/gallery-db";
// import "./db/contact-db";
import mock from "./mock";

mock.onAny().passThrough();
