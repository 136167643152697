import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./sidebar/sidebarSlice";
import menuReducer from "./menu/menuSlice";
import menuWisePageConfigReducer from "./menu/menuWisePageConfigSlice";
import timelineReducer from "./timeline/timelineSlice";
import blogReducer from "./blog/blogSlice";
import portfolioReducer from "./portfolio/portfolioSlice";
import aboutReducer from "./about/aboutSlice";
import themeReducer from "./theme/themeSlice";
import testimonialReducer from "./testimonial/testimonialSlice";
import homeReducer from "./home/homeSlice";
import serviceReducer from "./service/serviceSlice";
import galleryReducer from "./gallery/gallerySlice";
import contactReducer from "./contact/contactSlice";

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    menu: menuReducer,
    menuWisePageConfig: menuWisePageConfigReducer,
    timeline: timelineReducer,
    blog: blogReducer,
    portfolio: portfolioReducer,
    about: aboutReducer,
    theme: themeReducer,
    testimonial: testimonialReducer,
    home: homeReducer,
    service: serviceReducer,
    gallery: galleryReducer,
    contact: contactReducer,
  },
});
export default store;
