import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import PolicyItem from "../components/policy/PolicyItem";
import SideSearch from "../components/search/SideSearch";
import { gridGenerator } from "../helper/helper";
import { getAllPolicys, getPolicyAdvocacy } from "../redux/blog/blogSlice";
import { getRowsPerPage } from "../redux/menu/menuSlice";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";

const PolicyAdvocacy = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const policys = useSelector(getAllPolicys);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = useSelector(getRowsPerPage);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getPolicyAdvocacy());
  }, [currentMenuID]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      policys && policys.length > 0 && policys.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(policys && policys.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, policys]);

  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % policys.length;
    setItemOffset(newOffset);
  };
  return (
    <div className=" p-4">
      <div className="relative">
        <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
          {currentPageConfig && currentPageConfig.menu_title}
        </h2>
        <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
          {currentPageConfig && currentPageConfig.shadow_title}
        </h2>
      </div>
      {/* <p className="text-lg text-slate-700 my-12">Dr. Muyeed is a significant contributor to WASH policies and reports developed by the government at the national level (Pro-Poor Strategy 2020, National Hygiene Survey 2018, National Sanitation and Water Supply Strategy 2021 Response to Covid-19 Outbreak Through Water, Sanitation and Hygiene Interventions: Bangladesh Strategy Paper 2020-2023, Safely Managed On-Site Sanitation (SMOSS) 2022, Implementation Plan of Arsenic Mitigation for Water Supply: IPAM-WS, Menstrual Hygiene Management Strategy 2021 etc).</p> */}

      <p className="text-lg text-slate-700 my-12">
        Dr. Muyeed is a significant contributor to WASH policies and reports
        developed by the government at the national level (
        <a
          className="underline text-blue-600"
          href="https://psb.gov.bd/policies/ppse.pdf"
        >
          Pro-Poor Strategy 2020
        </a>
        ,{" "}
        <a
          className="underline text-blue-600"
          href="http://bbs.portal.gov.bd/sites/default/files/files/bbs.portal.gov.bd/page/b343a8b4_956b_45ca_872f_4cf9b2f1a6e0/2021-02-18-12-34-38806de91fa4ca8d9e70db96ecff4427.pdf"
        >
          National Hygiene Survey 2018
        </a>
        ,{" "}
        <a
          className="underline text-blue-600"
          href="https://psb.gov.bd/policies/nswssren.pdf"
        >
          National Sanitation and Water Supply Strategy 2021
        </a>{" "}
        <a
          className="underline text-blue-600"
          href="https://psb.gov.bd/policies/covidse.pdf"
        >
          Response to Covid-19 Outbreak Through Water, Sanitation and Hygiene
          Interventions: Bangladesh Strategy Paper 2020-2023
        </a>
        ,{" "}
        <a
          className="underline text-blue-600"
          href="https://washdata.org/report/bangladesh-2022-smoss-report"
        >
          Safely Managed On-Site Sanitation (SMOSS) 2022
        </a>
        ,{" "}
        <a
          className="underline text-blue-600"
          href="https://psb.gov.bd/policies/ipame.pdf"
        >
          Implementation Plan of Arsenic Mitigation for Water Supply: IPAM-WS
        </a>
        ,{" "}
        <a
          className="underline text-blue-600"
          href="https://psb.gov.bd/policies/nmhmsen.pdf"
        >
          Menstrual Hygiene Management Strategy 2021
        </a>{" "}
        etc).
      </p>

      <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4 mt-8">
        <div className={`flex-1 flex flex-col`}>
          <div className={`${gridGeneratorClassName}`}>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => (
                <PolicyItem
                  key={item.id}
                  item={item}
                  currentPageConfig={currentPageConfig}
                />
              ))}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            className="w-full flex items-center justify-center gap-6"
            activeClassName="bg-slate-200 rounded-[50%] py-2"
            pageLinkClassName="border px-4 py-2 rounded-[50%] hover:bg-slate-200"
          />
        </div>
      </div>
    </div>
  );
};

export default PolicyAdvocacy;
