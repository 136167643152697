import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dataFetch from "../../helper/dataFetch";


export const getMenus = createAsyncThunk("menu/getMenus", async () => {
  const response = await dataFetch.get("/api/menudb-app/menus");
  
  const data = await response.data.data;

  return data;
});
export const getMenuByPath = createAsyncThunk(
  "menu/getMenuByPath",
  async (params) => {
    const response = await dataFetch.get(`/api/menudb-app/menuByPath/${params}`);
    const data = await response.data.data;

    return data;
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menus: [],
    rowsPerPag: 9,
    menu: null,
  },
  reducers: {
    setAllMenu: (state, { payload }) => {
      state.menus = payload;
    },
    setRowsPerPage: (state, { payload }) => {
      state.rowsPerPag = payload;
    },
  },
  extraReducers: {
    [getMenus.pending]: () => {
      console.log("Fetching Menu Data...");
    },
    [getMenus.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getMenus.fulfilled]: (state, { payload }) => {
      return { ...state, menus: payload };
    },
    [getMenuByPath.pending]: () => {
      console.log("Fetching Menu Data...");
    },
    [getMenuByPath.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getMenuByPath.fulfilled]: (state, { payload }) => {
      return { ...state, menu: payload };
    },
  },
});
export const { setAllMenu, setRowsPerPage } = menuSlice.actions;
export const getAllMenu = (state) => state.menu.menus;
export const getMenu = (state) => state.menu.menu;
export const getRowsPerPage = (state) => state.menu.rowsPerPag;
export default menuSlice.reducer;
