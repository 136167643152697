import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlog,
  getCurrentblog,
  saveComment,
} from "../../redux/blog/blogSlice";

import { UilUser } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { UilComments } from "@iconscout/react-unicons";
import { UilAngleDoubleRight } from "@iconscout/react-unicons";
import Comment from "../comment/Comment";
import { useState } from "react";
import urlConfig from "../../helper/urlConfig";
const BlogDetails = ({ id, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const selectedBlog = useSelector(getCurrentblog);

  useEffect(() => {
    dispatch(getBlog(id));
  }, [id]);
  const handleClose = (e) => {
    e.preventDefault();
    handleCloseDrawer();
  };
  const handleSubmitComment = (e, obj) => {
    e.preventDefault();
    dispatch(saveComment(obj)).then(() => {
      handleCloseDrawer();
    });
  };
  const [customTag, setCustomTag] = useState([]);
  useEffect(() => {
    if (selectedBlog && selectedBlog.tags && selectedBlog.tags.length > 0) {
      let list = [];
      selectedBlog.tags.map((x) => {
        list.push(x.name);
      });
      setCustomTag(list);
    }
  }, [selectedBlog]);

  return (
    <div>
      {selectedBlog ? (
        <div className="p-4">
          <div className="flex items-center justify-end">
            <span
              className="border hover:cursor-pointer text-[20px] py-1 px-3 "
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
          <div className="mt-3">
            <img
              crossOrigin="anonymous"
              src={
                selectedBlog &&
                selectedBlog.images &&
                selectedBlog.images.length > 0
                  ? urlConfig.baseUrl + selectedBlog.images[0].upload_path
                  : null
              }
              alt={selectedBlog.title}
              className="w-full h-[220px] md:h-[380px] rounded-xl mb-6"
            />

            <div className="flex items-start justify-between gap-4 flex-col md:flex-row">
              <div className="flex-1 w-full flex gap-2 items-center justify-between">
                <div className="w-full flex flex-col items-start gap-4 break-all">
                  <div className="w-full flex items-start">
                    <label className="w-full">
                      {customTag.length > 0 && customTag.join(",")}
                    </label>
                  </div>
                  <div className="w-full flex flex-row md:flex-col items-center justify-between">
                    <div className="w-full">
                      <label className="flex items-start text-slate-500 text-sm text-left">
                        <span className="mr-4">
                          <UilUser />
                        </span>
                        {selectedBlog.author}
                      </label>
                      <label className="flex items-start text-slate-500 text-sm text-left">
                        <span className="mr-4">
                          <UilCalendarAlt />
                        </span>
                        {selectedBlog.publish_date}
                      </label>
                    </div>
                    <div className="w-full">
                      <label className="flex items-start text-slate-500 text-sm text-left">
                        <span className="mr-4">
                          <UilEye />
                        </span>{" "}
                        {selectedBlog.views} Views
                      </label>
                      <label className="flex items-start text-slate-500 text-sm text-left">
                        <span className="mr-4">
                          <UilComments />
                        </span>
                        {selectedBlog.comments.length} Comments
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-[3] flex flex-col">
                <label className="font-bold text-xl text-black">
                  {selectedBlog.title}
                </label>
                <p className="text-justify text-slate-400">
                  {selectedBlog.content}
                </p>
              </div>
            </div>
          </div>
          {/* <hr className="my-6" /> */}
          {/* <div>
            <label className="text-xl font-bold text-slate-700">
              {selectedBlog.comments && selectedBlog.comments.length > 0 ? (
                selectedBlog.comments.length + " Comments"
              ) : (
                <label className="text-slate-500 text-3xl">No comments</label>
              )}
            </label>
            {selectedBlog.comments && selectedBlog.comments.length > 0
              ? selectedBlog.comments.map((item, index) => (
                  <div className="w-full flex items-start justify-start gap-6 mt-3">
                    <div className="h-24 w-24 bg-slate-400"></div>
                    <div className="flex flex-col">
                      <label className="text-sm font-semibold">
                        {item.commenter}
                      </label>
                      <label className="text-xs text-slate-500">
                        {item.comment_date}
                      </label>
                      <p className="mt-4 text-justify text-slate-800 text-xs">
                        {item.comment}
                      </p>
                    </div>
                  </div>
                ))
              : null}
            <Comment id={id} handleSubmitComment={handleSubmitComment} />
          </div> */}
        </div>
      ) : null}
    </div>
  );
};

export default BlogDetails;
