import React, { useEffect, useState } from "react";
import { UilSearch } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllblogs,
  getAllTags,
  getBlogByTags,
  getBlogByTitle,
  getBlogs,
  getTags,
} from "../../redux/blog/blogSlice";

const SideSearchNewsAndEvents = ({ handleChangeTags, postType }) => {
  const dispatch = useDispatch();
  const tags = useSelector(getAllTags);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagCountSummary, setTagCountSummary] = useState([]);
  const [selectTagWithCount, setSelectTagWithCount] = useState([]);

  const [uniqueTags, setUniqueTags] = useState([])
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(getTags(postType));
    setSelectedTags([]);
  }, [postType]);

  useEffect(() => {
    let ut = tags && tags.length > 0 &&  tags.filter((item, i, ar) => ar.indexOf(item) === i);
    setUniqueTags(ut)
  }, [tags])
  
  const handleSelectTagsWithCount = (e, item) => {
    e.preventDefault();
    const items = [...selectTagWithCount];

    items[0] = item;
    setSelectTagWithCount(items);
    handleChangeTags(e, items);
  };

  const handleSelectTags = (e, item) => {
    e.preventDefault();
    let items = [...selectedTags];
    if (item == "All") {
      setSelectedTags(["All"]);
      handleChangeTags(e, ["All"]);
    } else {
      if (!items.includes(item)) {
        if (items.includes("All")) {
          items = items.filter((x) => x != "All");
          items.push(item);
        } else {
          items.push(item);
        }
        setSelectedTags(items);
        handleChangeTags(e, items);
      } else {
        setSelectedTags(items.filter((x) => x != item));
        handleChangeTags(e, items.filter((x) => x != item));
      }
    }
  };
  const handleSetSelectedTags = (item) => {
    return selectedTags.includes(item);
  };
  function getOccurrence(value) {
    var count = 0;
    for (let i = 0; i < tags.length; i++) {
        if (tags[i] == value) ++count;
    }
    return count;
  }

  useEffect(() => {
    var list = [];
    for (let i = 0; i < uniqueTags.length > 0; i++) {
      let count = getOccurrence(uniqueTags[i]);
      let obj = {
        item: uniqueTags[i],
        count: count,
      };
      list.push(obj);
    }
    setTagCountSummary(list);
  }, [tags && tags.length > 0, uniqueTags]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    dispatch(getBlogByTitle({type:postType, title:e.target.value}));
  };
  return (
    <>
      <div className="bg-slate-300 flex items-center justify-between px-6 py-4 rounded-full">
        <input
          placeholder="Search..."
          className="w-full bg-transparent border-none outline-none px-4"
          value={searchText}
          onChange={(e) => handleSearch(e)}
        />
        <UilSearch />
      </div>
      <div className="my-6">
        <h2 className="bg-indigo-700 py-2 text-center text-white">
          Post Categories
        </h2>
        <div className="mt-2 flex flex-wrap gap-4">
          {/* <div className="w-full flex items-center justify-between border-dotted border-b-2 pb-3">
            <label>{"All"}</label>
            <label></label>
          </div> */}
          {tagCountSummary &&
            tagCountSummary.length > 0 &&
            tagCountSummary.map((item, index) => (
              <div
                key={index}
                className="w-full flex items-center justify-between border-dotted border-b-2 pb-3"
              >
                <label
                  className="hover:underline hover:cursor-pointer"
                  onClick={(e) => handleSelectTagsWithCount(e, item.item)}
                >
                  {item.item}
                </label>
                <label>{item.count}</label>
              </div>
              //   <label
              //     key={index}
              //     className={`${
              //       handleSetSelectedTags(item) ? "bg-indigo-300" : ""
              //     } border px-5 py-2 hover:bg-slate-100 hover:cursor-pointer`}
              //     onClick={(e) => handleSelectTags(e, item)}
              //   >
              //     {item}
              //   </label>
            ))}
        </div>
      </div>
      <div className="">
        <h2 className="bg-indigo-700 py-2 text-center text-white">Tags</h2>
        <div className="mt-2 flex flex-wrap gap-2">
          <label
            className={`${
              handleSetSelectedTags("All") ? "bg-indigo-300" : ""
            } border px-5 py-2 hover:bg-slate-100 hover:cursor-pointer`}
            onClick={(e) => handleSelectTags(e, "All")}
          >
            All
          </label>
          {uniqueTags &&
            uniqueTags.length > 0 &&
            uniqueTags.map((item, index) => (
              <label
                key={index}
                className={`${
                  handleSetSelectedTags(item) ? "bg-indigo-300" : ""
                } border px-5 py-2 hover:bg-slate-100 hover:cursor-pointer`}
                onClick={(e) => handleSelectTags(e, item)}
              >
                {item}
              </label>
            ))}
        </div>
      </div>
    </>
  );
};

export default SideSearchNewsAndEvents;
