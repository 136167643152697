import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader/Loader";
import urlConfig from "../helper/urlConfig";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import {
  getsTimelineDataByType,
  getTimelineData,
  getTimelineDatas,
  getTimelineType,
  getTimelineTypes,
} from "../redux/timeline/timelineSlice";

const Timeline = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const timelineData = useSelector(getTimelineData);
  const [isLoading, setIsLoading] = useState(true);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const timelineTypes = useSelector(getTimelineType);
  const [selectedItem, setSelectedItem] = useState("All");

  useEffect(() => {
    dispatch(getTimelineTypes());
    dispatch(getsTimelineDataByType("All"));

    dispatch(getMenuWisePageConfig(currentMenuID)).then(() =>
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    );
  }, [currentMenuID]);

  const handleSelectTimelineType = (e, type) => {
    setSelectedItem(type);
    dispatch(getsTimelineDataByType(type));
  };
  return (
    <div className="w-full mx-auto rounded-3xl  min-h-screen p-4">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
          {currentPageConfig && currentPageConfig.menu_title}
        </h2>
        <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
          {currentPageConfig && currentPageConfig.shadow_title}
        </h2>
      </div>
      {/* <ol className="relative border-l border-l-blue-700 border-gray-200 dark:border-gray-700 pl-8">
                {timelineData &&
                    timelineData.length > 0 &&
                    timelineData.map((item, index) => (
                        <li className="mb-10 ml-6 ">
                            <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-white rounded-full ring-4 ring-blue-500 dark:ring-gray-900 dark:bg-indigo-900">
                                <i className={item.icon}></i>
                            </span>
                            <h3 className="pb-4 flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
                                {item.title}
                            </h3>
                            <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                {item.instituteType}: {item.institute}
                            </time>
                            <p className="mb-4 font-semibold text-sm text-gray-500 dark:text-gray-400">
                                {item.group +
                                    ", " +
                                    item.resultType +
                                    ": " +
                                    item.result}
                            </p>
                        </li>
                    ))}
            </ol> */}
      <div className="my-8">
        <ul className="w-full flex items-center justify-center gap-4 flex-wrap">
          <li
            className={`${
              selectedItem == "All" ? "active " : ""
            } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
            data-filter="*"
            onClick={(e) => handleSelectTimelineType(e, "All")}
          >
            All
          </li>
          {timelineTypes &&
            timelineTypes.length > 0 &&
            timelineTypes.map((item, index) => (
              <li
                key={item.id}
                className={`${
                  selectedItem == item ? "active " : ""
                } px-4 py-2 rounded-full hover:cursor-pointer hover:bg-indigo-400`}
                data-filter=".corporate"
                onClick={(e) => handleSelectTimelineType(e, item)}
              >
                {item}
              </li>
            ))}
        </ul>
      </div>

      <div className="w-full xl:w-[80%] xl:mx-auto relative mt-6">
        {/* <!-- Vertical middle line--> */}
        <div className="absolute hidden w-px h-full transform -translate-x-1/2 bg-indigo-500 lg:block left-1/2"></div>
        <div className="space-y-12 md:space-y-8">
          {timelineData &&
            timelineData.length > 0 &&
            timelineData.map((item, index) => (
              <div key={index}>
                {/* <!-- Left section --> */}
                <div className="flex flex-col items-center ">
                  <div
                    className={`w-full mt-2 flex items-center justify-center mx-auto ${
                      item.view_position == "left"
                        ? " lg:justify-start"
                        : " lg:justify-end"
                    }  `}
                  >
                    <div
                      className={`w-full sm:w-[80%] lg:w-1/2 ${
                        item.view_position == "left" ? "lg:pr-12" : "lg:pl-12"
                      }`}
                    >
                      <div className="p-4 bg-white rounded-3xl shadow-lg shadow-slate-300">
                        <p className="font-semibold text-normal text-slate-700 mt-4">
                          {item.title}
                        </p>
                        <p className="text-slate-500">{item.group}</p>
                        {item.img && item.img != "" ? (
                          <img
                          crossOrigin="anonymous"
                            className="rounded-2xl my-4 h-[250px] w-full"
                            src={urlConfig.baseUrl +item.img}
                            alt={item.title}
                          />
                        ) : null}
                        <p className="font-[600] text-sm text-slate-600">
                          {item.institute}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="absolute flex items-center justify-center w-14 h-14 transform -translate-x-1/2 lg:-translate-x-1/2 -translate-y-4 lg:-translate-y-4 bg-indigo-400 border-4 border-white rounded-full left-1/2 xs:translate-y-0">
                    <span className="text-white">{item.year}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
