import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlog,
  getCurrentblog,
  saveComment,
} from "../../redux/blog/blogSlice";

import { UilUser } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { UilComments } from "@iconscout/react-unicons";
import { UilAngleDoubleRight } from "@iconscout/react-unicons";
import Comment from "../comment/Comment";
import { useState } from "react";
import urlConfig from "../../helper/urlConfig";
const PolicyDetails = ({ id, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const selectedBlog = useSelector(getCurrentblog);

  useEffect(() => {
    dispatch(getBlog(id));
  }, [id]);
  const handleClose = (e) => {
    e.preventDefault();
    handleCloseDrawer();
  };
  const handleSubmitComment = (e, obj) => {
    e.preventDefault();
    dispatch(saveComment(obj)).then(() => {
      handleCloseDrawer();
    });
  };
  const [customTag, setCustomTag] = useState([]);
  useEffect(() => {
    if (selectedBlog && selectedBlog.tags && selectedBlog.tags.length > 0) {
      let list = [];
      selectedBlog.tags.map((x) => {
        list.push(x.name);
      });
      setCustomTag(list);
    }
  }, [selectedBlog]);

  return (
    <div>
      {selectedBlog ? (
        <div className="p-4">
          <div className="flex items-center justify-end">
            <span
              className="border hover:cursor-pointer text-[20px] py-1 px-3 "
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
          <div className="mt-3">
            <img
              crossOrigin="anonymous"
              src={
                selectedBlog &&
                selectedBlog.images &&
                selectedBlog.images.length > 0
                  ? urlConfig.baseUrl + selectedBlog.images[0].upload_path
                  : null
              }
              alt={selectedBlog.title}
              className="w-full h-[220px] md:h-[380px] rounded-xl mb-6"
            />

            <div className="flex items-start justify-between gap-4 flex-col md:flex-row">
             
              <div className="flex-[3] flex flex-col">
                <label className="font-bold text-xl text-black">
                  {selectedBlog.title}
                </label>
                <p className="text-justify text-slate-400">
                  {selectedBlog.content}
                </p>
                <a href={selectedBlog.url} target="_blank" className="text-indigo-600 break-words w-full">Link: {selectedBlog.url}</a>

              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default PolicyDetails;
