import React, { useEffect, useState } from "react";
import { UilUser } from "@iconscout/react-unicons";
import { UilCalendarAlt } from "@iconscout/react-unicons";
import { UilEye } from "@iconscout/react-unicons";
import { UilComments } from "@iconscout/react-unicons";
import { UilAngleDoubleRight } from "@iconscout/react-unicons";

import {
  captionLabelClassGenerator,
  imageClassGenerator,
  headingClassGenerator,
  paddingClassGenerator,
  sectionClassGenerator,
  buttonClassGenerator,
  renderContent,
} from "../../helper/helper";
import BlogDetails from "./BlogDetails";
import { useDispatch, useSelector } from "react-redux";
import { getBlog, getCurrentblog } from "../../redux/blog/blogSlice";
import urlConfig from "../../helper/urlConfig";

const BlogItem = ({ item, currentPageConfig }) => {
  const dispatch = useDispatch();
  const {
    id,
    img,
    tags,
    title,
    author,
    publish_date,
    views,
    comment_count,
    content,
  } = item;
  const [labelClass, setLabelClass] = useState("");
  const [imageClass, setImageClass] = useState("");
  const [headingClass, setHeadingClass] = useState("");
  const [paddingClass, setPaddingClass] = useState("");
  const [sectionClass, setSectionClass] = useState("");
  const [buttonClass, setButtonClass] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [isItemClicked, setIsItemClicked] = useState(false);
  const [clickedItem, setClickedItem] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setImgFile(urlConfig.baseUrl + img);
  }, [img]);
  useEffect(() => {
    setLabelClass(
      captionLabelClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setImageClass(
      imageClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setHeadingClass(
      headingClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setPaddingClass(
      paddingClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setSectionClass(
      sectionClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setButtonClass(
      buttonClassGenerator(currentPageConfig && currentPageConfig.columns)
    );
    setGeneratedContent(
      renderContent(currentPageConfig && currentPageConfig.columns, content)
    );
  }, [currentPageConfig]);
  const handleReadMore = (e, id) => {
    e.preventDefault();
    setIsItemClicked(true);
  };
  const handleCloseDrawer = (e) => {
    setIsItemClicked(false);
  };
  function onOverlayClick(e) {
    handleCloseDrawer();
    e.stopPropagation();
  }
  function onModalClick(e) {
    // need to stop propagation to ModalOverlay onClick event
    e.stopPropagation();
  }

   // Function to handle mouse enter
   const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [customTag, setCustomTag] = useState([]);
  useEffect(() => {
    if (tags && tags.length > 0) {
      let list = [];
      tags.map((x) => {
        list.push(x.name);
      });
      setCustomTag(list);
    }
  }, [tags]);

  return (
    <div
    className="w-full flex items-start gap-1 mb-6 relative h-[600px]"
    onMouseEnter={() => handleMouseEnter()}
    onMouseLeave={() => handleMouseLeave()}
  >
    <div
      className={`${
        currentPageConfig &&
        currentPageConfig.post_summary_info_type == "left"
          ? "w-[30%]"
          : "w-0 hidden"
      } flex flex-col items-end gap-4 break-all`}
    >
      <label className={`${labelClass}`}>
        {customTag.length > 0 && customTag.join(",")}
      </label>
      {/* ... (other labels) */}
    </div>
    <div
      className={`${
        currentPageConfig &&
        currentPageConfig.post_summary_info_type == "left"
          ? "w-[70%]"
          : "w-full"
      } ${paddingClass}`}
    >
      <div className={`${imageClass} relative`}>
        <img
          crossOrigin="anonymous"
          src={urlConfig.baseUrl + img}
          alt={title}
          className={`h-full w-full rounded-2xl ${isHovered ? "blur" : ""}`}
        />
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <button
            type="button"
            className={`${
              buttonClass
            } opacity-0 transition-opacity ${isHovered ? "opacity-100" : ""}`}
            onClick={(e) => handleReadMore(e, id)}
          >
            Read More
            <span>
              <UilAngleDoubleRight />
            </span>
          </button>
        </div>
      </div>
      {currentPageConfig &&
      currentPageConfig.post_summary_info_type == "below-image" ? (
        <div>
          <label className={`${labelClass} py-1`}>
            {customTag.length > 0 && customTag.join(",")}
          </label>
          {/* ... (other labels) */}
        </div>
      ) : null}
      <div className="">
        <h2 className={`${headingClass}`}>{title.slice(0, 100)}</h2>
        <section className={`${sectionClass}`}>
          <p dangerouslySetInnerHTML={{ __html: content.substring(0, 120) }}></p>
        </section>
      </div>
    </div>
    {isItemClicked ? (
      <div
        onClick={onOverlayClick}
        className="cursor-default z-[1000] fixed top-0 bottom-0 left-0 right-0 bg-slate-800/50 dark:bg-gray-800"
        tabIndex="-1"
      >
        <div
          onClick={onModalClick}
          className="p-8 fixed right-0 bottom-0 top-0  overflow-y-auto opacity-1 w-[90%] md:w-[60%] xl:w-[40%] bg-white z-[2000]"
        >
          <BlogDetails id={id} handleCloseDrawer={handleCloseDrawer} />
        </div>
      </div>
    ) : null}
  </div>
  
  );
};

export default BlogItem;
