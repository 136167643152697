import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getGalleryList = createAsyncThunk(
  "gallery/getGalleryList",
  async () => {
    const response = await dataFetch.get("/api/gallery-db/galleryData");
    const data = await response.data.data;
    return data;
  }
);
export const getGalleryConfig = createAsyncThunk(
  "gallery/getGalleryConfig",
  async () => {
    const response = await dataFetch.get("/api/gallery-db/galleryConfigData");
    const data = await response.data.data;
    return data;
  }
);
export const getGallery = createAsyncThunk(
  "gallery/getGallery",
  async (params) => {
    const response = await dataFetch.get(`/api/gallery-db/galleryById/${params}`);
    const data = await response.data.data;
    return data;
  }
);
const gallerySlice = createSlice({
  name: "gallery",
  initialState: {
    galleryData: [],
    galleryConfig: null,
    gallery: null,
  },
  reducers: {},
  extraReducers: {},
  extraReducers: {
    [getGalleryList.pending]: () => {
      console.log("Fetching Gallery List...");
    },
    [getGalleryList.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getGalleryList.fulfilled]: (state, { payload }) => {
      return { ...state, galleryData: payload };
    },
    [getGalleryConfig.pending]: () => {
      console.log("Fetching Gallery Config...");
    },
    [getGalleryConfig.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getGalleryConfig.fulfilled]: (state, { payload }) => {
      return { ...state, galleryConfig: payload };
    },
    [getGallery.pending]: () => {
      console.log("Fetching Gallery ...");
    },
    [getGallery.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getGallery.fulfilled]: (state, { payload }) => {
      return { ...state, gallery: payload };
    },
  },
});
export const { setIsCollapsed } = gallerySlice.actions;
export const getGalleryData = (state) => state.gallery.galleryData;
export const getGalleryConfigData = (state) => state.gallery.galleryConfig;
export const getCurrentGalleryData = (state) => state.gallery.gallery;
export default gallerySlice.reducer;
