import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import urlConfig from "../../helper/urlConfig";
import {
  getCurrentService,
  getService,
} from "../../redux/service/serviceSlice";

const ServiceDetails = ({ id, handleCloseDrawer }) => {
  const dispatch = useDispatch();
  const selectedService = useSelector(getCurrentService);

  useEffect(() => {
    dispatch(getService(id));
  }, [id]);
  const handleClose = (e) => {
    e.preventDefault();
    handleCloseDrawer();
  };
  return (
    <div>
      {selectedService ? (
        <div className="p-4 ">
          <div className="flex items-center justify-end">
            <span
              className="border hover:cursor-pointer text-[20px] py-1 px-3 "
              onClick={(e) => handleClose(e)}
            >
              &times;
            </span>
          </div>
          <div className="mt-3">
            
          <img crossOrigin="anonymous" src={urlConfig.baseUrl+selectedService.img} 
              alt={selectedService.title} className="h-full w-full rounded-2xl" />
          </div>
          <hr className="my-6" />
          <p className="font-semibold text-slate-600 text-xl">
            {selectedService.short_description}
          </p>
          <p className="text-slate-500 text-sm mt-4">
            {selectedService.description}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ServiceDetails;
