import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContact,
  getCurrentcontact,
  sendMessage,
} from "../redux/contact/contactSlice";
import {
  getCurrentMenuWisePageConfig,
  getCurrentSelectedMenuID,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import { getScreenSize } from "../redux/theme/themeSlice";

const Contact = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const contactData = useSelector(getCurrentcontact);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    dispatch(getMenuWisePageConfig(currentMenuID));
    dispatch(getContact());
  }, [currentMenuID]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "firstName") setFirstName(value);
    if (name == "lastName") setLastName(value);
    if (name == "email") setEmail(value);
    if (name == "message") setMessage(value);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const obj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      message: message,
    };
    dispatch(sendMessage(obj));
  };

  return (
    <div className="w-full mx-auto min-h-screen  p-4">
      {contactData && (
        <>
          <div className="relative">
            <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
              {currentPageConfig && currentPageConfig.menu_title}
            </h2>
            <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
              {currentPageConfig && currentPageConfig.shadow_title}
            </h2>
          </div>
          {contactData.title && (
            <h2 className="mt-12 text-2xl font-bold text-slate-600 text-center">
              {contactData.title}
            </h2>
          )}
          {contactData.subtitle && (
            <h2 className="text-base text-slate-500 text-center">
              {contactData.sub_title}
            </h2>
          )}
          <div className="mt-12 min-h-[400px] shadow-2xl rounded-2xl flex flex-col md:flex-row items-stretch w-full xl:w-[80%] xl:mx-auto ">
            <div className="flex-[.4] w-full p-12 bg-indigo-600 text-white rounded-2xl">
              <h2 className="text-3xl font-bold pb-12">Contact Information</h2>
              <div className="flex items-start gap-4 flex-col">
                <div className="flex items-center gap-4">
                {contactData.mobile?<i className="uil uil-phone"></i>:null}
                  <label>{contactData.mobile}</label>
                </div>
                <div className="flex items-center gap-4">
                {contactData.email ? <i className="uil uil-envelope-add"></i> :null}
                  <label>{contactData.email}</label>
                </div>
                <div className="flex items-center gap-4">
                {contactData.address ?<i className="uil uil-location-pin-alt"></i> : null}
                  <label>{contactData.address}</label>
                </div>
              </div>
              <div className="w-full  flex  gap-3 md:flex-row flex-wrap mt-16 px-2 md:px-4 justify-between">
                <a
                  href="https://www.facebook.com/aamuyeed"
                  target="_blank"
                  className="uil uil-facebook text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
                ></a>
                <a
                  href="https://www.youtube.com/channel/UC2yAPnC4e4NQrn2H9f_2kjA"
                  target="_blank"
                  className="uil uil-youtube text-white bg-indigo-500 hover:bg-indigo-700 rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
                ></a>
                <a
                  href="https://www.linkedin.com/in/amuyeed/"
                  target="_blank"
                  className="uil uil-linkedin text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
                ></a>
                <a
                  href="https://twitter.com/aamuyeed"
                  target="_blank"
                  className="uil uil-twitter text-white bg-indigo-500 hover:bg-indigo-700  rounded-[50%] p-2 flex items-center justify-center hover:cursor-pointer"
                ></a>
              </div>
            </div>
            <div className="flex-[.6] w-full p-3 md:p-12 flex flex-col gap-4 items-start">
              <input
                placeholder="First Name"
                name="firstName"
                value={firstName}
                onChange={handleChange}
                className="border-b border-b-slate-300 w-full  py-2 outline-none"
              />
              <input
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleChange}
                className="border-b border-b-slate-300 w-full  py-2 outline-none"
              />
              <input
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange}
                className="border-b border-b-slate-300 w-full  py-2 outline-none"
              />
              <textarea
                placeholder="Message"
                name="message"
                rows={5}
                value={message}
                onChange={handleChange}
                className="border border-slate-300 w-full p-2 outline-none"
              ></textarea>
              <button
                className="bg-indigo-500 hover:bg-indigo-700 text-white px-5 py-2 rounded-lg"
                onClick={(e) => handleSendMessage(e)}
              >
                Send Message
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Contact;
