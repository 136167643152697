import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMenu } from "../../redux/menu/menuSlice";
import { Link } from "react-router-dom";
import { useState } from "react";
import { setCurrentSelectedMenuID } from "../../redux/menu/menuWisePageConfigSlice";

const MobileMenu = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuList = useSelector(getAllMenu);
  const handleMenuClick = (e, id) => {
    // setClickedMenu(id);
    setIsMenuOpen(false);
    dispatch(setCurrentSelectedMenuID(id));
    dispatch(setCurrentSelectedMenuID(id));
  };
  return (
    <div className="fixed top-0 left-0 right-0 z-[99999999] bg-indigo-600 py-2">
      <div className="flex items-start justify-start flex-col p-2">
        <div
          className="h-[40px] w-[40px] rounded-full bg-indigo-400 text-white flex items-center justify-center"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {!isMenuOpen ? (
            <svg
              className="w-6 h-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M3 3h18v2H3V3zm0 6h18v2H3V9zm0 6h18v2H3v-2z" />
            </svg>
          ) : (
            <svg
              className="w-6 h-6 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M5 4.41l1.41-1.41 6.59 6.58 6.58-6.58 1.41 1.41-6.58 6.58 6.58 6.59-1.41 1.41-6.58-6.58-6.59 6.58-1.41-1.41 6.58-6.59-6.58-6.58z" />
            </svg>
          )}
        </div>
        <div
          className={`mt-4 flex flex-col gap-3  w-full`}
          style={{ display: !isMenuOpen ? "none" : "" }}
        >
          {menuList &&
            menuList.length > 0 &&
            menuList.map((item, index) => (
              <Link key={item.id} to={`${item.menu_path + ""}`} className=" ">
                <p
                  className="text-white "
                  onClick={(e) => handleMenuClick(e, item.id)}
                >
                  {<span>{item.menu_name}</span>}
                </p>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
