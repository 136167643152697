import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dataFetch from "../../helper/dataFetch";


export const getTimelineDatas = createAsyncThunk(
  "timeline/getTimelineDatas",
  async () => {
    const response = await dataFetch.get("/api/timelinedb-app/timelineData");
    const data = await response.data.data;

    return data;
  }
);
export const getTimelineTypes = createAsyncThunk(
  "portfolio/getTimelineTypes",
  async (params) => {
    const response = await dataFetch.get("/api/timelinedb-app/timelineTypes", {
      params,
    });
    const data = await response.data.data;
    return data;
  }
);
export const getsTimelineDataByType = createAsyncThunk(
  "portfolio/getsTimelineDataByType",
  async (params) => {
    const response = await dataFetch.get(`/api/timelinedb-app/timelineDataByType/${params}`);
    const data = await response.data.data;
    return data;
  }
);

const timelineSlice = createSlice({
  name: "timeline",
  initialState: {
    timelineData: [],
    portfolioTypes: [],
  },
  reducers: {
    settimelineData: (state, { payload }) => {
      state.timelineData = payload;
    },
  },
  extraReducers: {
    [getTimelineDatas.pending]: () => {
      console.log("Fetching Timeline Data...");
    },
    [getTimelineDatas.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getTimelineDatas.fulfilled]: (state, { payload }) => {
      return { ...state, timelineData: payload };
    },
    [getTimelineTypes.pending]: () => {
      console.log("Fetching Portfolio Type...");
    },
    [getTimelineTypes.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getTimelineTypes.fulfilled]: (state, { payload }) => {
      return { ...state, portfolioTypes: payload };
    },
    [getsTimelineDataByType.pending]: () => {
      console.log("Fetching Portfolio Data By Type...");
    },
    [getsTimelineDataByType.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getsTimelineDataByType.fulfilled]: (state, { payload }) => {
      return { ...state, timelineData: payload };
    },
  },
});
export const { setTimelineData } = timelineSlice.actions;
export const getTimelineData = (state) => state.timeline.timelineData;
export const getTimelineType = (state) => state.timeline.portfolioTypes;
export default timelineSlice.reducer;
