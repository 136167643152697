import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import BlogItem from "../components/blog/BlogItem";

import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentMenuWisePageConfig,
  getMenuWisePageConfig,
} from "../redux/menu/menuWisePageConfigSlice";
import { getCurrentSelectedMenuID } from "../redux/menu/menuWisePageConfigSlice";

import { gridGenerator } from "../helper/helper";
import { getRowsPerPage } from "../redux/menu/menuSlice";
import Loader from "../components/loader/Loader";
import { getAllblogs, getBlogByTags, getBlogs } from "../redux/blog/blogSlice";
import SideSearch from "../components/search/SideSearch";

const Blog = () => {
  const dispatch = useDispatch();
  const currentPageConfig = useSelector(getCurrentMenuWisePageConfig);
  const blogData = useSelector(getAllblogs);
  const currentMenuID = useSelector(getCurrentSelectedMenuID);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = useSelector(getRowsPerPage);
  const [gridGeneratorClassName, setGridGeneratorClassName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [initialTag, setInititalTag] = useState(["All"]);
  useEffect(() => {
    let obj = {
      type: "blog",
      tag: initialTag,
    };
    dispatch(getBlogByTags(obj));
    dispatch(getMenuWisePageConfig(currentMenuID)).then(() =>
      setTimeout(() => {
        setIsLoading(false);
      }, 1000)
    );
  }, [currentMenuID]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(
      blogData && blogData.length > 0 && blogData.slice(itemOffset, endOffset)
    );
    setPageCount(Math.ceil(blogData && blogData.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, blogData]);

  useEffect(() => {
    setGridGeneratorClassName(
      gridGenerator(currentPageConfig && currentPageConfig.columns)
    );
  }, [currentPageConfig]);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % blogData.length;
    setItemOffset(newOffset);
  };

  const handleChangeTags = (e,tags) => {
    e.preventDefault();
    let obj = {
      type: "blog",
      tag: tags,
    };
    dispatch(getBlogByTags(obj));
  };

  return (
    <div className="p-4">
      {isLoading ? <Loader /> : null}
      <div className="relative">
        <h2 className="z-[10] font-extrabold text-3xl md:text-5xl">
          {currentPageConfig && currentPageConfig.menu_title}
        </h2>
        <h2 className="font-extrabold text-3xl md:text-5xl opacity-10  absolute top-[25px] leading-[.6] left-0 z-[1]">
          {currentPageConfig && currentPageConfig.shadow_title}
        </h2>
      </div>
      <div className="w-full flex flex-col md:flex-row items-start justify-between gap-4 mt-8">
        <div
          className={`${
            currentPageConfig && currentPageConfig.sidebar_search
              ? "flex-[3]"
              : "flex-1"
          }  flex flex-col`}
        >
          <div className={`${gridGeneratorClassName}`}>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((item, index) => (
                <BlogItem
                  key={item.id}
                  item={item}
                  currentPageConfig={currentPageConfig}
                />
              ))}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">>"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<<"
            renderOnZeroPageCount={null}
            className="w-full flex items-center justify-center gap-6"
            activeClassName="bg-slate-200 rounded-[50%] py-2"
            pageLinkClassName="border px-4 py-2 rounded-[50%] hover:bg-slate-200"
          />
        </div>
        <div
          className={`${
            currentPageConfig && currentPageConfig.sidebar_search
              ? "flex-1"
              : "hidden"
          }  flex flex-col`}
        >
          <SideSearch handleChangeTags={handleChangeTags} />
        </div>
      </div>
    </div>
  );
};

export default Blog;
