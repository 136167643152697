import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import dataFetch from "../../helper/dataFetch";

export const getContact = createAsyncThunk(
  "contact/getContact",
  async (params) => {
    const response = await dataFetch.get("/api/contact-db/contactData", { params });
    const data = await response.data.data;
    return data;
  }
);

export const sendMessage = createAsyncThunk(
  "contact/sendMessage",
  async (obj) => {
    const response = await dataFetch.post(`/api/message/send`,{
      first_name: obj.first_name,
      last_name: obj.last_name,
      email: obj.email,
      message: obj.message,
    } );
    const data = await response.data;
    if(data.status == 200)
    alert("Message Sent successfully")

    return data;
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    contact: null,
  },
  reducers: {},
  extraReducers: {
    [getContact.pending]: () => {
      console.log("Fetching contact Data...");
    },
    [getContact.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getContact.fulfilled]: (state, { payload }) => {
      return { ...state, contact: payload };
    },
  },
});
export const {} = contactSlice.actions;
export const getCurrentcontact = (state) => state.contact.contact;
export default contactSlice.reducer;
