import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import dataFetch from "../../helper/dataFetch";

// export const getBlogs = createAsyncThunk("blog/getBlogs", async () => {
//   const response = await dataFetch.get("/api/post-db/posts");
//   const data = await response.data.data;

//   return data;
// });

export const getBlogs = createAsyncThunk("blog/getBlogs", async () => {
  const response = await dataFetch.get("/api/post-db/posts"); //dataFetch.get("/api/post-db/posts");
  const data = await response.data.data;

  return data;
});

export const getBlog = createAsyncThunk("blog/getBlog", async (params) => {
  const response = await dataFetch.get(`/api/post-db/postById/${ params }`);
  const data = await response.data.data;

  return data;
});

export const getTags = createAsyncThunk("blog/getTags", async (params) => {
  const response = await dataFetch.get(`/api/post-db/allTags/${ params }`);
  const data = await response.data.data;
  return data;
});

export const getBlogByTags = createAsyncThunk(
  "blog/getBlogByTags",
  async (obj) => {
    const response = await dataFetch.get(`/api/post-db/postByTags/${obj.type}/${obj.tag}`);
    const data = await response.data.data;

    return data;
  }
);
export const getPolicyAdvocacy = createAsyncThunk(
  "blog/getPolicyAdvocacy",
  async () => {
    const response = await dataFetch.get(`/api/post-db/postPolicyAdvocacy`);
    const data = await response.data.data;

    return data;
  }
);
export const getBlogWithCountByTag = createAsyncThunk(
  "blog/getBlogWithCountByTag",
  async (params) => {
    const response = await dataFetch.get("/api/post-db/postWithCountByTag", {
      params,
    });
    const data = await response.data.data;

    return data;
  }
);
export const getBlogByTitle = createAsyncThunk(
  "blog/getBlogByTitle",
  async (params) => {
    const response =params.title =='' ?await dataFetch.get(`/api/post-db/postByType/${ params.type }` ) : await dataFetch.get(`/api/post-db/searchPostsByTitle/${params.type}/${params.title}`);
    const data = await response.data.data;

    return data;
  }
);
export const getPostByType = createAsyncThunk(
  "blog/getPostByType",
  async (params) => {
    const response = await dataFetch.get(`/api/post-db/postByType/${ params }` );
    const data = await response.data.data;

    return data;
  }
);
export const saveComment = createAsyncThunk(
  "blog/saveComment",
  async (obj) => {
    const response = await dataFetch.post(`/api/comments/save/${obj.id}`,{
      commenter: obj.name,
      comment: obj.comment
    } );
    const data = await response.data;
    if(data.status == 200)
    alert("Comment saved successfully")

    return data;
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogs: [],
    blog: null,
    allTags: [],
    blogCount: 0,
    policys: []
  },
  reducers: {
    setAllblogs: (state, { payload }) => {
      state.blogs = payload;
    },
  },
  extraReducers: {
    [getBlogs.pending]: () => {
      console.log("Fetching Blog Data...");
    },
    [getBlogs.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getBlogs.fulfilled]: (state, { payload }) => {
      return { ...state, blogs: payload };
    },
    [getBlog.pending]: () => {
      console.log("Fetching Blog Data...");
    },
    [getBlog.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getBlog.fulfilled]: (state, { payload }) => {
      return { ...state, blog: payload };
    },
    [getTags.pending]: () => {
      console.log("Fetching Tags...");
    },
    [getTags.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getTags.fulfilled]: (state, { payload }) => {
      return { ...state, allTags: payload };
    },
    [getBlogByTags.pending]: () => {
      console.log("Fetching Blogs By Tags...");
    },
    [getBlogByTags.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getBlogByTags.fulfilled]: (state, { payload }) => {
      return { ...state, blogs: payload };
    },
    [getPolicyAdvocacy.pending]: () => {
      console.log("Fetching policys...");
    },
    [getPolicyAdvocacy.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getPolicyAdvocacy.fulfilled]: (state, { payload }) => {
      return { ...state, policys: payload };
    },
    
    [getBlogWithCountByTag.pending]: () => {
      console.log("Fetching Blogs Count By Tags...");
    },
    [getBlogWithCountByTag.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getBlogWithCountByTag.fulfilled]: (state, { payload }) => {
      return { ...state, blogCount: payload };
    },
    [getBlogByTitle.pending]: () => {
      console.log("Fetching Blogs By Title...");
    },
    [getBlogByTitle.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getBlogByTitle.fulfilled]: (state, { payload }) => {
      return { ...state, blogs: payload };
    },
    [getPostByType.pending]: () => {
      console.log("Fetching Blogs By Title...");
    },
    [getPostByType.rejected]: () => {
      console.log("Could not fetch data...");
    },
    [getPostByType.fulfilled]: (state, { payload }) => {
      return { ...state, blogs: payload };
    },
  },
});
export const { setAllblogs } = blogSlice.actions;
export const getAllblogs = (state) => state.blog.blogs;
export const getCurrentblog = (state) => state.blog.blog;
export const getAllTags = (state) => state.blog.allTags;
export const getAllPolicys = (state) => state.blog.policys;
export default blogSlice.reducer;
